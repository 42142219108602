import React, { useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  TablePagination,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
} from "@mui/material";
import toastr from "toastr";
import { makeGETRequest, makePOSTRequest } from "../../Api/api";

export interface Quotes {
  uuid: string;
  purchaseDate: string;
  isin: string;
  securityName: string;
  securityMaster: null;
  priceHistory: null;
  faceValue: null;
  sellPrice: number;
  image: null;
  securityType: string;
  purchasePrice: number;
  spread: number;
  festival_name_for_quote: string;
  minimumQuantity: string;
  maximumQuantity: string;
  isCurrentDayPurchaseDate: boolean;
  isPushed: boolean;
  purchaseYield: string;
  isBookStock: boolean;
  isHotDeal: boolean;
  isCurrentDay: boolean;
  isPrivatePlacement: boolean;
  valueDate: Date;
  sellYield: string;
  percentageCompleted: number;
  insertedAt: Date;
  updatedAt: Date;
}

const Quotes = () => {
  const [quotes, setQuotes] = useState<Quotes[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [filterIsin, setFilterIsin] = useState("");
  const [filterSecurityName, setFilterSecurityName] = useState("");
  const [filterSellYield, setFilterSellYield] = useState("");

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedQuote, setSelectedQuote] = useState<Quotes | null>(null);
  const [auctionYield, setAuctionYield] = useState("");
  const [numberOfBonds, setNumberOfBonds] = useState("");
  const [bidderPays, setBidderPays] = useState("");

  useEffect(() => {
    setIsLoading(true);
    makeGETRequest("/admin/quotes/get/all/")
      .then((resp) => {
        if (resp.status === 200) {
          setQuotes(resp.data);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        toastr.error("Failed to fetch quotes.");
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    setPage(0);
  }, [filterIsin, filterSecurityName, filterSellYield]);

  const filteredQuotes = useMemo(() => {
    return quotes.filter((quote) => {
      const isin = quote.isin.toLowerCase();
      const securityName = quote.securityName.toLowerCase();
      const sellYield = quote.sellYield.toLowerCase();
      return (
        (filterIsin ? isin.includes(filterIsin.toLowerCase()) : true) &&
        (filterSecurityName
          ? securityName.includes(filterSecurityName.toLowerCase())
          : true) &&
        (filterSellYield
          ? sellYield.includes(filterSellYield.toLowerCase())
          : true)
      );
    });
  }, [quotes, filterIsin, filterSecurityName, filterSellYield]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedQuotes =
    rowsPerPage > 0
      ? filteredQuotes.slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage
        )
      : filteredQuotes;

  const handlePushClick = (quote: Quotes) => {
    setSelectedQuote(quote);
    setAuctionYield("");
    setNumberOfBonds("");
    setBidderPays("");
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setSelectedQuote(null);
  };

  const handleDialogSubmit = () => {
    if (!selectedQuote) return;
    const payload = {
      security_master: selectedQuote.isin,
      quote: selectedQuote.uuid,
      auction_yield: parseFloat(auctionYield),
      number_of_bonds: parseInt(numberOfBonds, 10),
      bid_settlement_amount: parseFloat(bidderPays),
    };

    makePOSTRequest("bid/bid_create/", payload)
      .then((resp) => {
        if (resp.status === 200 || resp.status === 201) {
          toastr.success("Bid created successfully");
        } else {
          toastr.error("Failed to create bid");
        }
        handleDialogClose();
      })
      .catch((error) => {
        toastr.error("Failed to create bid");
        handleDialogClose();
      });
  };

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "300px",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <Box sx={{ p: 2, display: "flex", gap: 2, flexWrap: "wrap" }}>
        <TextField
          label="Filter by ISIN"
          value={filterIsin}
          onChange={(e) => setFilterIsin(e.target.value)}
          size="small"
        />
        <TextField
          label="Filter by Security Name"
          value={filterSecurityName}
          onChange={(e) => setFilterSecurityName(e.target.value)}
          size="small"
        />
        <TextField
          label="Filter by Sell Yield"
          value={filterSellYield}
          onChange={(e) => setFilterSellYield(e.target.value)}
          size="small"
        />
      </Box>

      <TableContainer>
        <Table>
          <TableHead sx={{ backgroundColor: "#e9e8e8" }}>
            <TableRow>
              <TableCell>ISIN</TableCell>
              <TableCell>Security Name</TableCell>
              <TableCell>Sell Yield</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedQuotes.map((quote) => (
              <TableRow key={quote.uuid}>
                <TableCell>{quote.isin}</TableCell>
                <TableCell>{quote.securityName}</TableCell>
                <TableCell>{quote.sellYield}</TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    onClick={() => handlePushClick(quote)}
                  >
                    Push
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, { value: -1, label: "All" }]}
        component="div"
        count={filteredQuotes.length}
        rowsPerPage={rowsPerPage > 0 ? rowsPerPage : filteredQuotes.length}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle>Push Quote for Bidding</DialogTitle>
        <DialogContent>
          <TextField
            label="Auction Yield"
            type="number"
            value={auctionYield}
            onChange={(e) => setAuctionYield(e.target.value)}
            margin="dense"
            fullWidth
          />
          <TextField
            label="Number of Bonds"
            type="number"
            value={numberOfBonds}
            onChange={(e) => setNumberOfBonds(e.target.value)}
            margin="dense"
            fullWidth
          />
          <TextField
            label="Bidder Pays"
            type="number"
            value={bidderPays}
            onChange={(e) => setBidderPays(e.target.value)}
            margin="dense"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button onClick={handleDialogSubmit} variant="contained">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Quotes;
