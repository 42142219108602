import React, { useEffect, useMemo, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import ExcelJS from "exceljs";
import Button from "@mui/material/Button";
import { Moment } from "moment";
import { makeGETRequestOnUserService } from "../../Api/api";
import moment from "moment";
import { toINR } from "../../Helpers/functions";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Avatar from "@mui/material/Avatar";
import CircularProgress from "@mui/material/CircularProgress";
import toastr from "toastr";
import ExcelImg from "../../images/excel-green.svg";

interface BondOrderHistoryResponse {
  uuid: string;
  bond_type: string;
  order_status: string;
  deal_type: string;
  bid_offer: string;
  isin: string;
  rating: string;
  rating_agency: string;
  value: string;
  minimum_order_value: string;
  type_of_yield: string;
  sell_yield: string;
  sell_price: string;
  settlement_type: string;
  gfd: string;
  deal_time_hours: string;
  deal_time_minutes: string;
  otootm: string;
  oto_participant_name: string;
  pro_client: string;
  buyer_client_name: string;
  direct_brokered: string;
  seller_broker_name: string;
  buyer_broker_name: string;
  negotiable_flag: string;
  disclosed_identity: string;
  initiator_custodian: string;
  initiator_reference_number: string;
  initiator_comment: string;
  extra_data: null;
  is_added_on_rfq: boolean;
  rfq_order_number: string;
  rfq_deal_id: string;
  no_of_bonds: number;
  principal_amount: string;
  modified_accrued_interest: string;
  stamp_duty: number;
  total_consideration: string;
  buyer_full_name: string;
  seller_name: string;
  initiator_ifsc: string;
  initiator_bank_account_number: string;
  initiator_dp_type: string;
  initiator_dpid: string;
  initiator_client_id: string;
  initiator_name: string;
  responder_ifsc: string;
  responder_bank_account_number: string;
  responder_dp_type: string;
  responder_dpid: string;
  responder_client_id: string;
  fund_credited: string;
  fund_debited: string;
  security_credited: string;
  security_debited: string;
  dealstatus_settled: string;
  payment_process: string;
  security_name: string;
  settlement_date: string;
  deal_status: string;
  isfundtransfer_Uploaded: boolean;
  insertedAt: Date;
  updatedAt: Date;
  user: string;
  image: string;
  issuer_name: string;
  facevalue: string;
  issuer_master_image_s3_url: string;
  last_call_date: string;
  last_maturity_date: string;
  cashflows: Cashflow[];
  deal_sheet_name: null;
  order_sheet_name: null;
  rfq_order_external_id: string;
  fundtransfer_image_name: null;
  payment_link_order_number: number;
  payment_link: string;
  razorpay_order_id: string;
  razorpay_fe_order_id: string;
  razorpay_transfer_order_id: string;
  payment_status: string;
  payment_link_generated_at: null;
}

interface Cashflow {
  uuid: string;
  date: Date;
  amount: string;
  type: string;
  inserted_at: Date;
  updated_at: Date;
  rfq_order: string;
}

interface CalendarCashflowValues {
  amount: number;
  type: string;
  uuid: string;
  isin: string;
  noOfBonds: number;
}

interface HeatmapData {
  year: number;
  month: string;
  numberOfDaysInMonth: number;
  datesOfMonth: {
    date: Moment;
    cashflows: CalendarCashflowValues[];
  }[];
}

type SortableKeys = keyof CalendarCashflowValues | "date" | "amountAfterTds";

const CashFlowRecordsTab = (props: any) => {
  const { currentUser } = props;
  const [orderHistory, setOrderHistory] = useState<
    BondOrderHistoryResponse[] | null
  >(null);
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState<SortableKeys>("date");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterIsin, setFilterIsin] = useState("");
  const [filterType, setFilterType] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [heatmapData, setHeatmapData] = useState<HeatmapData[]>([]);
  const [selectedYear, setSelectedYear] = useState(0);
  const [viewAll, setViewAll] = useState(false);

  useEffect(() => {
    if (isLoading) return;
    if (!orderHistory) {
      setHeatmapData([]);
      return;
    }
    const cashflowDates = orderHistory
      .filter((order) => order.deal_status === "Settled")
      .flatMap((order) =>
        order.cashflows.map((cashflow) => moment(cashflow.date))
      );
    if (cashflowDates.length === 0) {
      setHeatmapData([]);
      return;
    }

    const start = moment.min(cashflowDates).startOf("month");
    const end = moment.max(cashflowDates).endOf("month");

    const getHeatmapData = (): HeatmapData[] => {
      const data: HeatmapData[] = [];
      // Use a cloned variable to avoid mutating the original start date
      let currentDate = start.clone();
      while (currentDate.isSameOrBefore(end, "month")) {
        const year = currentDate.year();
        const month = currentDate.format("MMM");
        const numberOfDaysInMonth = currentDate.daysInMonth();
        const datesOfMonth: {
          date: Moment;
          cashflows: CalendarCashflowValues[];
        }[] = [];

        for (let i = 1; i <= numberOfDaysInMonth; i++) {
          const date = currentDate.clone().date(i);
          const cashflows = orderHistory
            .filter((order) => order.deal_status === "Settled")
            .flatMap((order) =>
              order.cashflows
                .filter((cashflow) => moment(cashflow.date).isSame(date, "day"))
                .map((cashflow) => ({
                  amount: Number(cashflow.amount),
                  type: cashflow.type,
                  uuid: order.uuid,
                  isin: order.isin,
                  noOfBonds: order.no_of_bonds,
                }))
            );
          datesOfMonth.push({ date, cashflows });
        }

        data.push({
          year,
          month,
          numberOfDaysInMonth,
          datesOfMonth,
        });

        currentDate.add(1, "month");
      }
      return data;
    };
    const data = getHeatmapData();
    setHeatmapData(data);
    setSelectedYear(data.length ? data[0].year : 0);
  }, [orderHistory, isLoading]);

  const memoizedHeatmapData = useMemo(
    () =>
      !viewAll
        ? heatmapData.filter((i) => i.year === selectedYear)
        : heatmapData,
    [heatmapData, selectedYear, viewAll]
  );

  const uniqueYears: number[] = Array.from(
    new Set(heatmapData.map((item) => item.year))
  );

  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);
    makeGETRequestOnUserService(
      `order_management_v2/orders/history/${currentUser.uuid}/`
    )
      .then((resp) => {
        if (isMounted) {
          if (resp.status === 200) {
            setOrderHistory(resp.data);
          }
          setIsLoading(false);
        }
      })
      .catch((error) => {
        if (isMounted) {
          toastr.error(error);
          setIsLoading(false);
        }
      });

    return () => {
      isMounted = false;
    };
  }, [currentUser.uuid]);

  useEffect(() => {
    setPage(0);
  }, [memoizedHeatmapData, filterIsin, filterType]);

  const handleRequestSort = (property: SortableKeys) => {
    if (property === "isin" || property === "noOfBonds") return;
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const flattenedData = useMemo(() => {
    const aggregatedData = new Map<
      string,
      CalendarCashflowValues & { date: any; amountAfterTds: number }
    >();
    memoizedHeatmapData.forEach(({ datesOfMonth }) => {
      datesOfMonth.forEach(({ date, cashflows }) => {
        cashflows.forEach((cashflow) => {
          const key = `${cashflow.isin}-${date.format("YYYY-MM-DD")}-${
            cashflow.type
          }`;
          if (aggregatedData.has(key)) {
            const existing = aggregatedData.get(key)!;
            existing.amount += cashflow.amount;
            existing.amountAfterTds +=
              cashflow.type === "Interest"
                ? cashflow.amount * 0.9
                : cashflow.amount;
            existing.noOfBonds += cashflow.noOfBonds;
          } else {
            aggregatedData.set(key, {
              ...cashflow,
              date,
              amountAfterTds:
                cashflow.type === "Interest"
                  ? cashflow.amount * 0.9
                  : cashflow.amount,
            });
          }
        });
      });
    });
    return Array.from(aggregatedData.values());
  }, [memoizedHeatmapData]);

  const filteredData = useMemo(
    () =>
      flattenedData.filter(
        (row) =>
          (filterIsin ? row.isin.includes(filterIsin) : true) &&
          (filterType ? row.type === filterType : true)
      ),
    [flattenedData, filterIsin, filterType]
  );

  const sortedData = useMemo(
    () =>
      [...filteredData].sort((a, b) => {
        if (orderBy === "date") {
          return order === "asc"
            ? a.date.valueOf() - b.date.valueOf()
            : b.date.valueOf() - a.date.valueOf();
        }
        if (a[orderBy] < b[orderBy]) return order === "asc" ? -1 : 1;
        if (a[orderBy] > b[orderBy]) return order === "asc" ? 1 : -1;
        return 0;
      }),
    [filteredData, orderBy, order]
  );

  const handleExportToExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Cashflow Data");

    const header = [
      "ISIN",
      "Amount",
      "Amount After TDS",
      "Type",
      "No. of Bonds",
      "Date",
    ];
    worksheet.addRow(header);

    sortedData.forEach((row) => {
      worksheet.addRow([
        row.isin,
        row.amount,
        row.amountAfterTds,
        row.type,
        row.noOfBonds,
        row.date.format("DD MMM YYYY"),
      ]);
    });

    worksheet.eachRow((row, rowNumber) => {
      row.eachCell((cell, colNumber) => {
        cell.alignment = { horizontal: "left" };

        if (rowNumber === 1) {
          cell.font = { bold: true };
        } else {
          if (colNumber === 2 || colNumber === 3) {
            cell.numFmt = "₹#,##0.00";
          }
          if (colNumber === 4) {
            const cellValue = cell.value;
            if (cellValue === "Interest") {
              cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "FFEEF8EE" },
              };
            } else {
              cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "FFF1F0FD" },
              };
            }
          }
        }
      });
    });

    worksheet.columns.forEach((column) => {
      if (column && typeof column.eachCell === "function") {
        let maxLength = 10;
        column.eachCell({ includeEmpty: true }, (cell) => {
          const columnLength = cell.value ? cell.value.toString().length : 0;
          if (columnLength > maxLength) {
            maxLength = columnLength;
          }
        });
        column.width = maxLength + 3;
      }
    });

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `Cashflow_${selectedYear > 0 ? selectedYear : "View_All"}_${
      currentUser.firstName
    }_${currentUser.lastName}.xlsx`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="300px"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ maxWidth: "90%", mx: "auto", zoom: 0.9 }}>
      <Stack
        direction="row"
        spacing={1}
        sx={{
          overflowX: "auto",
          "&::-webkit-scrollbar": { display: "none", width: 0 },
          pb: 2,
        }}
      >
        {uniqueYears.map((item, index) => (
          <Chip
            key={index}
            sx={{
              fontWeight: 800,
              bgcolor: item === selectedYear ? "#7569EE1A" : "transparent",
              color: "#7659EE",
              ":hover": {
                bgcolor: "#7569EE1A",
              },
            }}
            avatar={
              <Avatar sx={{ bgcolor: "transparent" }}>
                <CalendarMonthIcon sx={{ color: "#ec4f56" }} fontSize="small" />
              </Avatar>
            }
            label={item}
            variant={item === selectedYear ? "filled" : "outlined"}
            onClick={() => {
              setSelectedYear(item);
              setViewAll(false);
            }}
          />
        ))}
        <Chip
          sx={{
            fontWeight: 800,
            bgcolor: viewAll ? "#7569EE1A" : "transparent",
            color: "#7659EE",
            ":hover": {
              bgcolor: "#7569EE1A",
            },
          }}
          avatar={
            <Avatar sx={{ bgcolor: "transparent" }}>
              <CalendarMonthIcon sx={{ color: "#ec4f56" }} fontSize="small" />
            </Avatar>
          }
          label={"View All"}
          variant={viewAll ? "filled" : "outlined"}
          onClick={() => {
            setSelectedYear(-1);
            setViewAll(!viewAll);
          }}
        />
      </Stack>
      <Box
        sx={{ borderRadius: 3, border: "1px solid grey", overflow: "hidden" }}
      >
        <Box sx={{ display: "flex", gap: 2, padding: 2 }}>
          <TextField
            label="Filter by ISIN"
            variant="outlined"
            size="small"
            value={filterIsin}
            onChange={(e) => setFilterIsin(e.target.value)}
          />
          <TextField
            select
            label="Filter by Type"
            variant="outlined"
            size="small"
            fullWidth
            sx={{ maxWidth: 150 }}
            value={filterType}
            onChange={(e) => setFilterType(e.target.value)}
          >
            <MenuItem value="">All</MenuItem>
            <MenuItem value="Interest">Interest</MenuItem>
            <MenuItem value="Maturity">Maturity</MenuItem>
          </TextField>
          <Button
            variant="outlined"
            color="success"
            sx={{ borderRadius: 2 }}
            onClick={handleExportToExcel}
            startIcon={
              <Box
                component="img"
                src={ExcelImg}
                sx={{ width: 18, height: 18 }}
                alt="Excel Icon"
              />
            }
          >
            Export to Excel
          </Button>
        </Box>
        <TableContainer>
          <Table>
            <TableHead sx={{ backgroundColor: "#f5f5f5" }}>
              <TableRow>
                {[
                  "ISIN",
                  "Amount",
                  "Amount After TDS",
                  "Type",
                  "No. of Bonds",
                  "Date",
                ].map((head, index) => {
                  const columnKey = [
                    "isin",
                    "amount",
                    "amountAfterTds",
                    "type",
                    "noOfBonds",
                    "date",
                  ][index] as SortableKeys;
                  return (
                    <TableCell key={columnKey}>
                      {columnKey === "isin" || columnKey === "noOfBonds" ? (
                        head
                      ) : (
                        <TableSortLabel
                          sx={{
                            "&.Mui-active .MuiTableSortLabel-icon": {
                              color: "info.main",
                            },
                          }}
                          active={orderBy === columnKey}
                          direction={orderBy === columnKey ? order : "asc"}
                          onClick={() => handleRequestSort(columnKey)}
                        >
                          {head}
                        </TableSortLabel>
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedData
                .slice(
                  page * rowsPerPage,
                  rowsPerPage === -1
                    ? undefined
                    : page * rowsPerPage + rowsPerPage
                )
                .map((row, index) => (
                  <TableRow key={index}>
                    <TableCell
                      sx={{ color: "info.main", fontWeight: 600, opacity: 0.8 }}
                    >
                      {row.isin}
                    </TableCell>
                    <TableCell>{toINR(row.amount, 2)}</TableCell>
                    <TableCell
                      sx={{
                        background:
                          "linear-gradient(45deg, #1c5284, #39e240, #e6e8ef)",
                        backgroundClip: "text",
                        WebkitBackgroundClip: "text",
                        color:
                          row.type === "Interest" ? "transparent" : "inherit",
                        fontWeight: row.type === "Interest" ? 800 : 600,
                      }}
                    >
                      {toINR(row.amountAfterTds, 2)}
                    </TableCell>
                    <TableCell
                      sx={{
                        bgcolor:
                          row.type === "Interest" ? "#EEF8EE" : "#7659EE1A",
                      }}
                    >
                      {row.type}
                    </TableCell>
                    <TableCell>{row.noOfBonds}</TableCell>
                    <TableCell sx={{ fontWeight: 600, opacity: 0.7 }}>
                      {row.date.format("DD MMM YYYY")}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, { value: -1, label: "All" }]}
          component="div"
          count={filteredData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </Box>
  );
};

export default CashFlowRecordsTab;
