import React, { useMemo } from "react";
import Grid from "@mui/material/Grid";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import {
  downloadUserServiceFileUsingGET,
  makeDELETERequestOnUserServiceWithQueryParameter,
  makeGETRequestOnUserService,
} from "../../Api/api";
import toastr from "toastr";
import { RouteComponentProps } from "react-router-dom";
import { ActionButtons, OrderDetailsInterface } from "./interfaces";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import { StyledButton } from "./NewRFQOrderDetails";

export const downloadPDFFile = (
  data: string | Blob,
  filename: string
): Promise<void> => {
  return new Promise((resolve, reject) => {
    try {
      let blob: Blob;

      if (typeof data === "string") {
        const binaryData = Buffer.from(data, "base64");
        blob = new Blob([new Uint8Array(binaryData)], {
          type: "application/pdf",
        });
      } else {
        blob = data;
      }

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      resolve();
    } catch (error) {
      reject(error);
    }
  });
};

const DealInfoActionButtons = ({
  orderDetails,
  orderUUID,
  props,
}: {
  orderDetails: OrderDetailsInterface;
  orderUUID: string;
  props: RouteComponentProps;
}) => {
  const downloadQuoteReceipt = () => {
    makeGETRequestOnUserService(
      `order_management_v2/quote_receipt/download/${orderUUID}/`
    )
      .then((response) => {
        if (response && response.status === 200) {
          downloadPDFFile(
            response.data.pdf_file,
            `Quote Receipt ${orderDetails.buyer_full_name}.pdf`
          );
          toastr.success("Quote Receipt Downloaded Successfully");
        }
      })
      .catch((error) => toastr.error(error));
  };
  const downloadOrdersheet = () => {
    downloadUserServiceFileUsingGET(
      `/digio/download_pdf/${orderDetails?.rfq_deal_id}/order/`
    )
      .then((response) => {
        if (response && response.status === 200) {
          toastr.success("Order Sheet Downloaded Successfully");
          downloadPDFFile(
            response.data,
            `Order_Receipt_${orderDetails?.rfq_deal_id}.pdf`
          );
        }
      })
      .catch((error) => toastr.error(error));
  };
  const downloadDealConfirmation = () => {
    downloadUserServiceFileUsingGET(
      `/digio/download_pdf/${orderDetails?.rfq_deal_id}/deal/`
    )
      .then((response) => {
        if (response && response.status === 200) {
          toastr.success("Deal Sheet Downloaded Successfully");
          downloadPDFFile(
            response.data,
            `Dealsheet_${orderDetails?.rfq_deal_id}.pdf`
          );
        }
      })
      .catch((error) => toastr.error(error));
  };
  const downloadFundTransferProof = () => {
    downloadUserServiceFileUsingGET(
      `/order_management_v2/download_fundtransfer/${orderUUID}/`
    )
      .then((response) => {
        if (response && response.status === 200) {
          downloadPDFFile(
            response.data,
            `Fund_Transfer_Proof_${orderDetails?.rfq_deal_id}.${(
              orderDetails?.fundtransfer_image_name || ""
            )
              .split(".")
              .pop()!}`
          );
          toastr.success("Fund Transfer Proof Downloaded Successfully");
        }
      })
      .catch((error) => {
        toastr.error(error);
        toastr.warning("Please try after a minute");
      });
  };
  const downloadCashflow = () => {
    makeGETRequestOnUserService(
      `order_management_v2/cashflow_pdf/download/${orderUUID}/`
    )
      .then((response) => {
        if (response && response.status === 200) {
          downloadPDFFile(
            response.data.pdf_file,
            `Cashflow_${orderDetails?.rfq_deal_id}.pdf`
          );
          toastr.success("Cashflow Downloaded Successfully");
        }
      })
      .catch((error) => {
        toastr.error(error);
      });
  };
  const deleteRFQOrder = () => {
    makeDELETERequestOnUserServiceWithQueryParameter(
      `/order_management_v2/rfq_order/delete/${orderUUID}/`
    )
      .then((response) => {
        if (response && response.status === 204) {
          toastr.success("RFQ order deleted successfully");
          setTimeout(() => {
            props.history.push(`/rfq_orders`);
          }, 2000);
        }
      })
      .catch((error) => toastr.error(error));
  };

  const dealInformationActionButtons: ActionButtons[] = useMemo(
    () => [
      {
        name: "Download Quote Receipt",
        color: "secondary",
        icon: <DownloadRoundedIcon />,
        disabled: false,
        handleClick: () => downloadQuoteReceipt(),
      },
      {
        name: "Download Order Receipt",
        color: "secondary",
        icon: <DownloadRoundedIcon />,
        disabled: !orderDetails?.is_added_on_rfq,
        handleClick: () => downloadOrdersheet(),
      },
      {
        name: "Download Deal Confirmation",
        color: "secondary",
        icon: <DownloadRoundedIcon />,
        disabled: !orderDetails?.is_added_on_rfq,
        handleClick: () => downloadDealConfirmation(),
      },
      {
        name: "Download Fund Transfer Proof",
        color: "secondary",
        icon: <DownloadRoundedIcon />,
        disabled: !orderDetails?.isfundtransfer_Uploaded,
        handleClick: () => downloadFundTransferProof(),
      },
      {
        name: "Download Cashflow",
        color: "secondary",
        icon: <DownloadRoundedIcon />,
        disabled: false,
        handleClick: () => downloadCashflow(),
      },
      JSON.parse(localStorage.getItem("loginUser")!)?.isSuperuser && {
        name: "Delete RFQ Order",
        color: "error",
        icon: <DeleteForeverRoundedIcon />,
        handleClick: () => deleteRFQOrder(),
      },
    ],
    [orderDetails]
  );

  return (
    <Grid container spacing={2} mt={4}>
      {dealInformationActionButtons
        .filter((item) => item.name && item.name.length)
        .map((button, index) => (
          <Grid item key={index} xs={6} sm={4} md={"auto"}>
            <StyledButton
              startIcon={button.icon}
              color={button.color}
              onClick={button.handleClick}
              disabled={button.disabled}
            >
              {button.name}
            </StyledButton>
          </Grid>
        ))}
    </Grid>
  );
};

export default DealInfoActionButtons;
