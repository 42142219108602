import React, { useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import { sxRoot, sxText, sxTypo, sxBox } from "../../Helpers/helpers";
import TextField from "@mui/material/TextField";
import Listbox from "../Listbox";
import {
  makeGETRequest,
  makeGETRequestOnUserService,
  makePOSTRequest,
  makePOSTRequestOnUserService,
} from "../../Api/api";
import { IUser } from "../YieldCalculator/Interfaces";
import { BondDetails, IQuoteData, UserDetails, ICashflow } from "./interfaces";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import toastr from "toastr";
import {
  StyledSelectInput,
  StyledTextInput,
} from "../StyledComponents/StyledInputs";
import FormHelperText from "@mui/material/FormHelperText";
import moment from "moment";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import {
  corporateBonds_moneyMarket_xirr,
  zcb_mld_xirr,
  gsec_sdl_xirr,
} from "./cashflowAPIs";
import { RouteComponentProps, withRouter } from "react-router-dom";

interface IUserNameID {
  name: string;
  id: string;
}

export const sortDate = (a: any, b: any) => {
  const [dayA, monthA, yearA] = a.date.split("/").map(Number);
  const [dayB, monthB, yearB] = b.date.split("/").map(Number);
  const dateA = new Date(yearA, monthA - 1, dayA);
  const dateB = new Date(yearB, monthB - 1, dayB);
  return dateA.getTime() - dateB.getTime();
};

function isGsecSdlTBill(securityType: string) {
  return [
    "State Development Loans",
    "Government Securities",
    "T Bill",
  ].includes(securityType);
}

const callRelevantCashflowAPI = (bondDetails: BondDetails) => {
  let securityType = bondDetails?.securityType.type;
  if (securityType === "Corporate Bonds")
    return corporateBonds_moneyMarket_xirr(bondDetails!);
  if (
    securityType === "Market Linked Debentures" ||
    securityType === "Zero Coupon Bonds"
  )
    return zcb_mld_xirr(bondDetails!);
  if (isGsecSdlTBill(securityType as string))
    return gsec_sdl_xirr(bondDetails!);
};

const generateCashflow = async (bondDetails: BondDetails) => {
  let faceValue = Number(bondDetails?.pricePerBond.split(" ")[0])!;
  let securityType = bondDetails?.securityType.type;
  try {
    const cashflowApisResponse = await callRelevantCashflowAPI(bondDetails);
    if (cashflowApisResponse && cashflowApisResponse.status === 200) {
      const { data } = cashflowApisResponse;
      const cashflowArray: ICashflow[] = [];
      if (securityType === "Corporate Bonds") {
        for (let i = 1; i < data.cashflow.AdjustedValueDate.length; i++) {
          const interest = Number(data.cashflow.Interest[i]);
          const maturity = Number(data.cashflow.Maturity[i]);
          if (interest !== 0) {
            const cashflow: ICashflow = {
              date: data.cashflow.AdjustedValueDate[i],
              amount: (interest * faceValue) / 100,
              type: "Interest",
            };
            cashflowArray.push(cashflow);
          }
          if (maturity !== 0) {
            const cashflow: ICashflow = {
              date: data.cashflow.AdjustedValueDate[i],
              amount: (maturity * faceValue) / 100,
              type: "Maturity",
            };
            cashflowArray.push(cashflow);
          }
        }
      }
      if (
        securityType === "Market Linked Debentures" ||
        securityType === "Zero Coupon Bonds"
      ) {
        for (let i = 1; i < data.cashflowDf.dates.length; i++) {
          const date = data.cashflowDf.dates[i];
          const amount = Number(data.cashflowDf.amounts[i]);
          const cashflow: ICashflow = {
            date: moment(date, "YYYY-MM-DD").format("DD/MM/YYYY").toString(),
            amount: (amount * faceValue) / 100,
            type: "Maturity",
          };
          cashflowArray.push(cashflow);
        }
      }
      if (
        securityType === "State Development Loans" ||
        securityType === "Government Securities" ||
        securityType === "T Bill"
      ) {
        for (let i = 1; i < data.cashflow.ValueDate.length; i++) {
          const interest = Number(data.cashflow.Interest[i]);
          const maturity = Number(data.cashflow.Maturity[i]);
          if (interest !== 0) {
            const cashflow: ICashflow = {
              date: data.cashflow.ValueDate[i],
              amount: (interest * faceValue) / 100,
              type: "Interest",
            };
            cashflowArray.push(cashflow);
          }
          if (maturity !== 0) {
            const cashflow: ICashflow = {
              date: data.cashflow.ValueDate[i],
              amount: (maturity * faceValue) / 100,
              type: "Maturity",
            };
            cashflowArray.push(cashflow);
          }
        }
      }
      return cashflowArray;
    }
  } catch (error) {
    toastr.error("Error fetching cashflow.", JSON.stringify(error));
  }
};

const initialInputs = {
  count: "",
  price: "",
  yield: "",
  principle: "",
  accruedInterest: "",
  stampDuty: "",
  totalConsideration: "",
  settlementDate: "",
  paymentProcess: "",
};

const CreateRFQOrder: React.FC<RouteComponentProps> = (props) => {
  const [userOpen, setUserOpen] = useState(false);
  const [users, setUsers] = useState<readonly IUserNameID[]>([]);
  const [user, setUser] = useState<IUserNameID | null>(null);
  const [isinOpen, setIsinOpen] = useState(false);
  const [isins, setIsins] = useState<readonly IUserNameID[]>([]);
  const [isin, setIsin] = useState<IUserNameID | null>(null);
  const [bondDetails, setBondDetails] = useState<BondDetails | null>(null);
  const [userDetails, setUserDetails] = useState<UserDetails | null>(null);
  const [inputs, setInputs] = useState(initialInputs);
  const [cashflowRecords, setCashflowRecords] = React.useState<any[]>([]);
  const loadingUsers = userOpen && users.length === 0;
  const loadingIsins = isinOpen && isins.length === 0;

  let faceValue = Number(bondDetails?.pricePerBond.split(" ")[0])!;
  let securityType = bondDetails?.securityType.type;

  useEffect(() => {
    let active = true;
    if (!loadingUsers) {
      return undefined;
    }
    makeGETRequestOnUserService("/user/user_list/")
      .then((response) => {
        if (response && response.status === 200) {
          const { data } = response;
          if (active) {
            const optionsArray = (data as IUser[])
              .filter((item) => item.areKYCDetailsVerified)
              .map((item) => ({
                name: `${item.firstName} ${item.lastName} - ${item.mobileNumber}`,
                id: item.uuid,
              }));
            setUsers([...optionsArray]);
          }
        }
      })
      .catch((error) => toastr.error(error));
    return () => {
      active = false;
    };
  }, [loadingUsers]);

  useEffect(() => {
    if (!userOpen) {
      setUsers([]);
    }
  }, [userOpen]);

  useEffect(() => {
    let active = true;
    if (!loadingIsins) {
      return undefined;
    }
    makePOSTRequest(`admin/quotes/filter/v2/`)
      .then((response) => {
        if (response && response.status === 200) {
          const { data } = response;
          if (active) {
            const optionsArray = (data as IQuoteData[]).map((item) => ({
              name: `${item.isin} - ${item.security_name}`,
              id: item.isin,
            }));
            setIsins([...optionsArray]);
          }
        }
      })
      .catch((error) => toastr.error(error));
    return () => {
      active = false;
    };
  }, [loadingIsins]);

  useEffect(() => {
    if (!isinOpen) {
      setIsins([]);
    }
  }, [isinOpen]);

  useEffect(() => {
    if (isin) {
      makeGETRequest(`admin/security_master/get/${isin.id}/`)
        .then(async (response) => {
          if (response && response.status === 200) {
            const { data } = response;
            const cashflow = await generateCashflow(data);
            cashflow?.length && setCashflowRecords(cashflow);
            setBondDetails(data);
          }
        })
        .catch((error) => toastr.error(error));
    } else {
      setBondDetails(null);
      setInputs(initialInputs);
    }
  }, [isin]);

  useEffect(() => {
    if (user) {
      makeGETRequestOnUserService(`user/get/${user.id}/`)
        .then((response) => {
          if (response && response.status === 200) {
            const { data } = response;
            setUserDetails(data);
          }
        })
        .catch((error) => toastr.error(error));
    } else {
      setUserDetails(null);
    }
  }, [user]);

  const inputData = useMemo(
    () => [
      {
        label: "Sell Yield",
        value: inputs.yield,
        key: "yield",
      },
      {
        label: "Sell Price",
        value: inputs.price,
        key: "price",
      },
      {
        label: "No of Bonds",
        value: inputs.count,
        key: "count",
      },
      {
        label: "Principal Amount",
        value: inputs.principle,
        key: "principle",
      },
      {
        label: "Modified Accrued Interest",
        value: inputs.accruedInterest,
        key: "accruedInterest",
      },
      {
        label: "Stamp Duty",
        value: inputs.stampDuty,
        key: "stampDuty",
      },
      {
        label: "Total Consideration without Stamp Duty",
        value: inputs.totalConsideration,
        key: "totalConsideration",
      },
      {
        label: "Settlement Date",
        value: inputs.settlementDate,
        key: "settlementDate",
      },
      {
        label: "Payment Process",
        value: inputs.paymentProcess,
        key: "paymentProcess",
      },
    ],
    [
      inputs.count,
      inputs.accruedInterest,
      inputs.totalConsideration,
      inputs.settlementDate,
      inputs.paymentProcess,
      inputs.stampDuty,
      inputs.principle,
      inputs.yield,
      inputs.price,
    ]
  );

  const orderData = useMemo(
    () => [
      { label: "User", value: userDetails?.uuid ?? "", helperText: "" },
      { label: "ISIN", value: bondDetails?.isin ?? "", helperText: "" },
      {
        label: "Bond Type",
        value: isGsecSdlTBill(securityType as string) ? "GSEC" : "ICDM",
        helperText: "",
      },
      {
        label: "Minimum Order Value",
        value: faceValue * Number(inputs.count),
        helperText: "",
      },
      {
        label: "Value",
        value: faceValue * Number(inputs.count),
        helperText: "",
      },
      {
        label: "Sell Price",
        value: bondDetails?.quoteDetails?.sellPrice ?? "",
        helperText: "",
      },
      {
        label: "Security Name",
        value: bondDetails?.securityName ?? "",
        helperText: "",
      },
      {
        label: "Settlement Type",
        value:
          inputs.paymentProcess === "PAYMENT GATEWAY"
            ? 0
            : isGsecSdlTBill(securityType as string)
            ? 1
            : 0,
        helperText: "",
      },
      { label: "Deal Type", value: "OBP", helperText: "" },
      { label: "Bid Offer", value: "BID", helperText: "" },
      {
        label: "Rating",
        value:
          bondDetails?.ratingOrganizationMasters[0]?.rating?.rating ?? "AAA",
        helperText: "",
      },
      {
        label: "Rating Agency",
        value:
          bondDetails?.ratingOrganizationMasters[0]?.organization?.name ??
          "CRISIL",
        helperText: "",
      },
      {
        label: "Type of Yield",
        value: bondDetails?.callOptions?.length ? "YTC" : "YTM",
        helperText: "",
      },
      { label: "Deal Time Hours", value: moment().format("H"), helperText: "" },
      {
        label: "Deal Time Minutes",
        value: moment().format("mm"),
        helperText: "",
      },
      { label: "OTO Participant Name", value: "SMEST", helperText: "" },
      {
        label: "Buyer Client Name",
        value:
          (userDetails?.ucc_external_id
            ? userDetails?.ucc_external_id
            : userDetails?.smestID) ?? "",
        helperText: "",
      },
      {
        label: "Buyer Full Name",
        value: userDetails?.panCard.name ?? "",
        helperText: "",
      },
      { label: "Seller Name", value: "SMEST", helperText: "" },
      {
        label: "Initiator IFSC",
        value:
          userDetails?.bankDetails?.find((item) => item.is_default)?.ifscCode ??
          "",
        helperText: "",
      },
      {
        label: "Initiator Bank Account Number",
        value:
          userDetails?.bankDetails?.find((item) => item.is_default)
            ?.accountNumber ?? "",
        helperText: "",
      },
      {
        label: "Initiator DP Type",
        value:
          userDetails?.dematDetails?.find((item) => item.is_default)?.dpType ??
          "",
        helperText: "",
      },
      {
        label: "Initiator DP ID",
        value:
          userDetails?.dematDetails
            ?.find((item) => item.is_default)
            ?.id.substring(0, 8) ?? "",
        helperText: "",
      },
      {
        label: "Initiator Client ID",
        value:
          (userDetails?.dematDetails?.find((item) => item.is_default)
            ?.dpType === "NSDL"
            ? userDetails?.dematDetails
                ?.find((item) => item.is_default)
                ?.id.substring(8)
            : userDetails?.dematDetails?.find((item) => item.is_default)?.id) ??
          "",
        helperText: "",
      },
      { label: "Initiator Name", value: "SMEST", helperText: "" },
      { label: "Responder IFSC", value: "KKBK0000661", helperText: "" },
      {
        label: "Responder Bank Account Number",
        value: "9813006261",
        helperText: "",
      },
      { label: "Responder DP Type", value: "NSDL", helperText: "" },
      { label: "Responder DP ID", value: "IN303655", helperText: "" },
      { label: "Responder Client ID", value: "10255881", helperText: "" },
      { label: "Pro Client", value: "CLIENT", helperText: "" },
    ],
    [
      bondDetails,
      userDetails,
      inputs.count,
      inputs.paymentProcess,
      securityType,
    ]
  );

  const handleOrder = () => {
    let payload = {
      user: userDetails?.uuid,
      bond_type: isGsecSdlTBill(securityType as string) ? "GSEC" : "ICDM",
      isin: bondDetails?.isin,
      rating:
        bondDetails?.ratingOrganizationMasters[0]?.rating?.rating ?? "AAA",
      rating_agency:
        bondDetails?.ratingOrganizationMasters[0]?.organization?.name ??
        "CRISIL",
      value: faceValue * Number(inputs.count),
      minimum_order_value: faceValue * Number(inputs.count),
      type_of_yield: bondDetails?.callOptions?.length ? "YTC" : "YTM",
      sell_yield: inputs.yield,
      sell_price: inputs.price,
      dirtyprice:
        (Number(inputs.totalConsideration) /
          (faceValue * Number(inputs.count))) *
        100,
      sellerclientcode: "SMEST",
      quotetype: "BID",
      settlement_type:
        inputs.paymentProcess === "PAYMENT GATEWAY"
          ? 0
          : isGsecSdlTBill(securityType as string)
          ? 1
          : 0,
      deal_time_hours: moment().format("H"),
      deal_time_minutes: moment().format("mm"),
      buyer_client_name: userDetails?.ucc_external_id
        ? userDetails?.ucc_external_id
        : userDetails?.smestID,
      no_of_bonds: Number(inputs.count),
      principal_amount: Number(inputs.principle),
      modified_accrued_interest: Number(inputs.accruedInterest),
      stamp_duty: Number(inputs.stampDuty),
      total_consideration: Number(inputs.totalConsideration),
      buyer_full_name: userDetails?.panCard.name,
      initiator_ifsc: userDetails?.bankDetails.find((item) => item.is_default)
        ?.ifscCode,
      initiator_bank_account_number: userDetails?.bankDetails.find(
        (item) => item.is_default
      )?.accountNumber,
      initiator_dp_type: userDetails?.dematDetails.find(
        (item) => item.is_default
      )?.dpType,
      initiator_dpid: userDetails?.dematDetails
        .find((item) => item.is_default)
        ?.id.substring(0, 8),
      initiator_client_id:
        userDetails?.dematDetails.find((item) => item.is_default)?.dpType ===
        "NSDL"
          ? userDetails?.dematDetails
              .find((item) => item.is_default)
              ?.id.substring(8)
          : userDetails?.dematDetails.find((item) => item.is_default)?.id,
      settlement_date: moment(inputs.settlementDate, "YYYY-MM-DD").format(
        "MMM DD,YYYY"
      ),
      security_name: bondDetails?.securityName,
      payment_process: inputs.paymentProcess,
      cashflow_records: cashflowRecords?.map((item) => ({
        ...item,
        amount: (Number(item.amount) * Number(inputs.count)).toFixed(4),
      })),
      s3_url: bondDetails?.issuerOfSecurity.s3Url,
      last_call_date: bondDetails?.callOptions
        ?.sort((a, b) => sortDate(a, b))
        .at(-1)?.date,
      last_maturity_date: bondDetails?.maturities
        ?.sort((a, b) => sortDate(a, b))
        .at(-1)?.date,
    };
    makePOSTRequestOnUserService("order_management_v2/orders/", payload)
      .then((response) => {
        if (response && response.status === 201) {
          toastr.success("Order placed successfully.");
          props.history.push("/rfq_orders");
        }
      })
      .catch((error) => toastr.error(error));
  };

  return (
    <Box
      sx={{
        maxWidth: ["90%", "80%"],
        mx: "auto",
        my: 5,
        bgcolor: "#FFFFFF",
        p: 2,
        borderRadius: 5,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box sx={{ width: ["100%", 400], mx: "auto" }}>
        <Autocomplete
          id={"user-select"}
          value={user}
          onChange={(_, newValue) => {
            setUser(newValue);
          }}
          open={userOpen}
          onOpen={() => {
            setUserOpen(true);
          }}
          onClose={() => {
            setUserOpen(false);
          }}
          isOptionEqualToValue={(option, value) => option === value}
          getOptionLabel={(option) => option.name}
          options={users}
          loading={loadingUsers}
          size="medium"
          sx={sxRoot}
          popupIcon={<ExpandMoreIcon />}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select User"
              sx={(theme) => sxText(theme)}
              id={"user-select"}
              fullWidth
              margin="dense"
              variant="standard"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loadingUsers ? (
                      <CircularProgress color="error" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          renderOption={(props, option, { selected }) => (
            <Typography {...props} sx={sxTypo(selected)} variant="h6">
              {option.name}
            </Typography>
          )}
          PaperComponent={({ children }) => {
            return <Box sx={sxBox}>{children}</Box>;
          }}
          ListboxComponent={Listbox}
        />
        <Autocomplete
          id={"isin-select"}
          value={isin}
          onChange={(_, newValue) => {
            setIsin(newValue);
          }}
          open={isinOpen}
          onOpen={() => {
            setIsinOpen(true);
          }}
          onClose={() => {
            setIsinOpen(false);
          }}
          isOptionEqualToValue={(option, value) => option === value}
          getOptionLabel={(option) => option.name}
          options={isins}
          loading={loadingIsins}
          size="medium"
          sx={sxRoot}
          popupIcon={<ExpandMoreIcon />}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select Isin"
              sx={(theme) => sxText(theme)}
              id={"isin-select"}
              fullWidth
              margin="dense"
              variant="standard"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loadingUsers ? (
                      <CircularProgress color="error" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          renderOption={(props, option, { selected }) => (
            <Typography {...props} sx={sxTypo(selected)} variant="h6">
              {option.name}
            </Typography>
          )}
          PaperComponent={({ children }) => {
            return <Box sx={sxBox}>{children}</Box>;
          }}
          ListboxComponent={Listbox}
        />
      </Box>
      <Grid container spacing={2} sx={{ mt: 4 }}>
        {inputData.map((item, index) => (
          <Grid key={index} item xs={12} sm={4} md={3}>
            {item.key === "paymentProcess" ? (
              <FormControl fullWidth variant="standard" size="small">
                <InputLabel
                  shrink
                  htmlFor={`${item.label.toLowerCase().replace(/\s+/g, "-")}`}
                >
                  {item.label}
                </InputLabel>
                <Select
                  id={`${item.label.toLowerCase().replace(/\s+/g, "-")}`}
                  value={item.value}
                  label={item.label}
                  onChange={(event) =>
                    setInputs((prev) => ({
                      ...prev,
                      paymentProcess: event.target.value,
                    }))
                  }
                  input={<StyledSelectInput />}
                >
                  <MenuItem value={"BANK TRANSFER"}>BANK TRANSFER</MenuItem>
                  <MenuItem value={"PAYMENT GATEWAY"}>PAYMENT GATEWAY</MenuItem>
                </Select>
              </FormControl>
            ) : item.key === "settlementDate" ? (
              <FormControl fullWidth variant="standard" size="small">
                <InputLabel
                  shrink
                  htmlFor={`${item.label.toLowerCase().replace(/\s+/g, "-")}`}
                >
                  {item.label}
                </InputLabel>
                <StyledTextInput
                  id={`${item.label.toLowerCase().replace(/\s+/g, "-")}`}
                  value={item.value}
                  type="date"
                  onChange={(event) =>
                    setInputs((prev) => ({
                      ...prev,
                      settlementDate: event.target.value,
                    }))
                  }
                />
              </FormControl>
            ) : (
              <FormControl fullWidth variant="standard">
                <InputLabel
                  shrink
                  htmlFor={`${item.label.toLowerCase().replace(/\s+/g, "-")}`}
                >
                  {item.label}
                </InputLabel>
                <StyledTextInput
                  id={`${item.label.toLowerCase().replace(/\s+/g, "-")}`}
                  value={item.value}
                  onChange={(event) =>
                    setInputs((prev) => ({
                      ...prev,
                      [item.key]: event.target.value,
                    }))
                  }
                  type="number"
                  inputMode="numeric"
                />
              </FormControl>
            )}
          </Grid>
        ))}
      </Grid>
      <Box
        component="hr"
        sx={{
          borderColor: "#7659EE",
          opacity: 0.5,
          my: 4,
          width: "100%",
        }}
      />
      <Grid container spacing={2}>
        {orderData.map((item, index) => (
          <Grid key={index} item xs={12} sm={4} md={3}>
            <FormControl fullWidth variant="standard">
              <InputLabel
                shrink
                htmlFor={`${item.label.toLowerCase().replace(/\s+/g, "-")}`}
              >
                {item.label}
              </InputLabel>
              <StyledTextInput
                id={`${item.label.toLowerCase().replace(/\s+/g, "-")}`}
                disabled
                value={item.value}
              />
              {item.helperText && (
                <FormHelperText
                  sx={{
                    color: "#45E142",
                    fontSize: 10,
                    ml: 1,
                  }}
                >
                  {item.helperText}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        ))}
      </Grid>
      <Button
        sx={{ mt: 4 }}
        variant="contained"
        onClick={handleOrder}
        disabled={user === null || isin === null}
      >
        Order
      </Button>
    </Box>
  );
};

export default withRouter(CreateRFQOrder);
