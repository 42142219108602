import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { StyledTextInput } from "../StyledComponents/StyledInputs";
import toastr from "toastr";
import {
  makeGETRequestOnUserService,
  makePOSTRequestOnUserService,
  makePUTRequestOnUserService,
} from "../../Api/api";
import moment from "moment";

export interface NomineeDetailsInterface {
  uuid: string;
  userId: string;
  name: string;
  date_of_birth: Date;
  relationship: string;
  mobileNumber: string;
  insertedAt: Date;
  updatedAt: Date;
}

const NomineeDetails = (props: any) => {
  const { userUUID, areNomineeDetailsSubmitted } = props;
  const [nomineeDetails, setNomineeDetails] = useState<NomineeDetailsInterface>(
    {
      uuid: "",
      userId: userUUID,
      name: "",
      date_of_birth: new Date(),
      relationship: "",
      mobileNumber: "",
      insertedAt: new Date(),
      updatedAt: new Date(),
    }
  );
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let active = true;
    if (!loading) {
      return undefined;
    }
    makeGETRequestOnUserService(`user/get_nominee/${userUUID}/`)
      .then((resp) => {
        if (active && resp.status == 200) {
          if (typeof resp.data === "object") {
            setNomineeDetails(resp.data);
          }
        }
      })
      .catch((error) => toastr.error(error))
      .finally(() => {
        active && setLoading(false);
      });
    return () => {
      active = false;
    };
  }, [loading]);

  const handleCreateNominee = () => {
    makePOSTRequestOnUserService(`user/create_nominee/`, {
      userId: userUUID,
      name: nomineeDetails?.name,
      date_of_birth: moment(nomineeDetails?.date_of_birth, "YYYY-MM-DD").format(
        "DD/MM/YYYY"
      ),
      relationship: nomineeDetails?.relationship,
      mobileNumber: nomineeDetails?.mobileNumber,
    })
      .then((resp) => {
        if (resp.status === 201) {
          toastr.success("Nominee Added Successfully!");
          setLoading(true);
        }
      })
      .catch((error) => toastr.error(error));
  };

  const handleUpdateNominee = () => {
    makePUTRequestOnUserService(`user/update_nominee/${nomineeDetails.uuid}/`, {
      userId: userUUID,
      name: nomineeDetails?.name,
      date_of_birth: moment(nomineeDetails?.date_of_birth, "YYYY-MM-DD").format(
        "DD/MM/YYYY"
      ),
      relationship: nomineeDetails?.relationship,
      mobileNumber: nomineeDetails?.mobileNumber,
    })
      .then((resp) => {
        if (resp.status === 200) {
          toastr.success("Nominee Details Updated Successfully!");
          setLoading(true);
        }
      })
      .catch((error) => toastr.error(error));
  };

  return (
    <Box sx={{ maxWidth: ["90%", "80%"], mx: "auto", my: 5 }}>
      {loading ? (
        <CircularProgress color="error" />
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Box sx={{ bgcolor: "#FFFFFF", p: 2, borderRadius: 5 }}>
            <Grid container spacing={1}>
              {Object.keys(nomineeDetails)
                .filter(
                  (item) =>
                    !["uuid", "insertedAt", "updatedAt", "userId"].includes(
                      item
                    )
                )
                .map((item, index) => (
                  <Grid item xs={6} key={index}>
                    <FormControl fullWidth variant="standard">
                      <InputLabel shrink htmlFor={item}>
                        {item
                          .replace(/([A-Z])/g, " $1")
                          .replace(/^./, (str) => str.toUpperCase())}
                      </InputLabel>
                      <StyledTextInput
                        id={`${item}_${index}`}
                        name={item}
                        type={
                          item === "date_of_birth"
                            ? "date"
                            : item === "mobileNumber"
                            ? "number"
                            : "text"
                        }
                        value={
                          nomineeDetails[item as keyof NomineeDetailsInterface]
                        }
                        onChange={(event) => {
                          const value = event.target.value;
                          setNomineeDetails((prev) => ({
                            ...prev!,
                            [item as keyof NomineeDetailsInterface]: value,
                          }));
                        }}
                      />
                    </FormControl>
                  </Grid>
                ))}
            </Grid>
          </Box>
          <Button
            variant="contained"
            size="large"
            fullWidth={false}
            onClick={() =>
              areNomineeDetailsSubmitted
                ? handleUpdateNominee()
                : handleCreateNominee()
            }
          >
            {areNomineeDetailsSubmitted ? "Update" : "Add"} Nominee
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default NomineeDetails;
