import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { alpha } from "@mui/material/styles";
import BasicInfo from "../components/BidWars/BasicInfo";
import Users from "../components/BidWars/Users";
import Quotes from "../components/BidWars/Quotes";
import Bids from "../components/BidWars/Bids";
import PriceHistory from "../components/BidWars/PriceHistory";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  dir?: string;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const TAB_OPTIONS = [
  {
    title: "Basic Info",
    component: <BasicInfo />,
  },
  {
    title: "Users",
    component: <Users />,
  },
  {
    title: "Quotes",
    component: <Quotes />,
  },
  {
    title: "Bids",
    component: <Bids />,
  },
  {
    title: "Price History",
    component: <PriceHistory />,
  },
];

const BidWars = () => {
  const [tab, setTab] = useState(0);
  const handleTabChange = (event: React.SyntheticEvent, value: any) => {
    setTab(value);
  };
  return (
    <Box
      sx={{
        maxWidth: "90%",
        mx: "auto",
        borderRadius: 3,
        border: "1px solid grey",
        my: 2,
      }}
    >
      <Box sx={{ mb: 2 }}>
        <Tabs
          value={tab}
          onChange={handleTabChange}
          variant="scrollable"
          sx={{
            position: "relative",
            ".hr": {
              position: "absolute",
              zIndex: 1,
              bottom: -5,
              left: 1,
              border: 0,
              width: "100%",
              height: 1.5,
              borderRadius: 50,
              background:
                "linear-gradient(90deg, #8d8d8e 0%, rgba(255, 255, 255, 0.537116) 62.76%, rgba(255, 255, 255, 0) 100%)",
            },
            "span.MuiTabs-indicator": {
              zIndex: 2,
              height: 3,
              bottom: 1.2,
              borderRadius: 50,
              bgcolor: "#7569EE",
              transition: "all 1000ms cubic-bezier(0.25, 0.1, 0, 0.86) 100ms",
            },
            ".MuiTab-root": {
              pb: 0,
              textTransform: "uppercase",
              color: `${alpha("#8d8d8e", 0.8)}`,
            },
            ".Mui-selected": {
              color: "#7569EE !important",
            },
            "span.MuiTouchRipple-root": {
              display: "none",
            },
          }}
        >
          {TAB_OPTIONS.map((item, index) => (
            <Tab key={index} label={item.title} />
          ))}
          <Box component="hr" className="hr" />
        </Tabs>
        <Box sx={{ my: 2 }}>
          {TAB_OPTIONS.map((item, index) => (
            <TabPanel value={tab} key={index} index={index}>
              {item.component}
            </TabPanel>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default BidWars;
