import React, { useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  CircularProgress,
  TablePagination,
  TextField,
} from "@mui/material";
import toastr from "toastr";
import { User } from "../../pages/Users/DownloadPermissions";
import {
  makeDELETERequestOnUserServiceWithQueryParameter,
  makeGETRequestOnUserService,
  makePOSTRequestOnUserService,
} from "../../Api/api";

type BidInvitation = {
  uuid: string;
  inserted_at: string;
  updated_at: string;
  user_uuid: string;
};

const Users = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [bidInvitations, setBidInvitations] = useState<BidInvitation[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [filterName, setFilterName] = useState("");
  const [filterMobile, setFilterMobile] = useState("");
  const [filterEmail, setFilterEmail] = useState("");
  const [filterLeadOwner, setFilterLeadOwner] = useState("");
  const fetchData = () => {
    setIsLoading(true);
    Promise.all([
      makeGETRequestOnUserService("/user/user_list/"),
      makeGETRequestOnUserService("/bid_invitation_list/"),
    ])
      .then(([usersResp, bidResp]) => {
        if (usersResp.status === 200 && bidResp.status === 200) {
          const allUsers: User[] = usersResp.data;
          const bids: BidInvitation[] = bidResp.data;
          setBidInvitations(bids);
          const bidUserUUIDs = Array.from(
            new Set(bids.map((bid) => bid.user_uuid))
          );
          const filteredUsers = allUsers.filter((user) =>
            bidUserUUIDs.includes(user.uuid)
          );
          setUsers(filteredUsers);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        toastr.error("Failed to fetch user list or bid invitation list.");
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    setPage(0);
  }, [filterName, filterMobile, filterEmail, filterLeadOwner]);

  const filteredUsers = useMemo(() => {
    return users.filter((user) => {
      const fullName = `${user.firstName} ${user.lastName}`.toLowerCase();
      const mobile = user.mobileNumber.toLowerCase();
      const email = user.emailId.toLowerCase();
      const leadOwner = user.leadowner ? user.leadowner.toLowerCase() : "";
      return (
        (filterName ? fullName.includes(filterName.toLowerCase()) : true) &&
        (filterMobile ? mobile.includes(filterMobile.toLowerCase()) : true) &&
        (filterEmail ? email.includes(filterEmail.toLowerCase()) : true) &&
        (filterLeadOwner
          ? leadOwner.includes(filterLeadOwner.toLowerCase())
          : true)
      );
    });
  }, [users, filterName, filterMobile, filterEmail, filterLeadOwner]);

  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    user: User
  ) => {
    const newValue = event.target.checked;
    setUsers((prevUsers) =>
      prevUsers.map((u) =>
        u.uuid === user.uuid ? { ...u, is_allowed_for_bid_war: newValue } : u
      )
    );

    if (newValue) {
      makePOSTRequestOnUserService("user_allowed_for_bid_war/", {
        user_ids: [user.uuid],
        operation_type: "add",
      })
        .then((resp) => {
          if (resp.status === 200) {
            toastr.success("Invitation Accepted successfully");
          } else {
            toastr.error("Failed to accept invitation");
            setUsers((prevUsers) =>
              prevUsers.map((u) =>
                u.uuid === user.uuid
                  ? { ...u, is_allowed_for_bid_war: !newValue }
                  : u
              )
            );
          }
        })
        .catch((error) => {
          toastr.error("Failed to accept invitation");
          setUsers((prevUsers) =>
            prevUsers.map((u) =>
              u.uuid === user.uuid
                ? { ...u, is_allowed_for_bid_war: !newValue }
                : u
            )
          );
        });
    } else {
      const bidInvitation = bidInvitations.find(
        (bid) => bid.user_uuid === user.uuid
      );
      if (!bidInvitation) {
        toastr.error("No bid invitation found to delete.");
        return;
      }
      makeDELETERequestOnUserServiceWithQueryParameter(
        `bid_invitation_delete/${bidInvitation.uuid}/`
      )
        .then((resp) => {
          if (resp.status === 204) {
            toastr.success("Invitation Unaccepted successfully");
            fetchData();
          } else {
            toastr.error("Failed to unaccept invitation");
            setUsers((prevUsers) =>
              prevUsers.map((u) =>
                u.uuid === user.uuid
                  ? { ...u, is_allowed_for_bid_war: !newValue }
                  : u
              )
            );
          }
        })
        .catch((error) => {
          toastr.error("Failed to unaccept invitation");
          setUsers((prevUsers) =>
            prevUsers.map((u) =>
              u.uuid === user.uuid
                ? { ...u, is_allowed_for_bid_war: !newValue }
                : u
            )
          );
        });
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedUsers =
    rowsPerPage > 0
      ? filteredUsers.slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage
        )
      : filteredUsers;

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "300px",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <Box>
      <Box sx={{ p: 2, display: "flex", gap: 2, flexWrap: "wrap" }}>
        <TextField
          label="Filter by Name"
          value={filterName}
          onChange={(e) => setFilterName(e.target.value)}
          size="small"
        />
        <TextField
          label="Filter by Mobile Number"
          value={filterMobile}
          onChange={(e) => setFilterMobile(e.target.value)}
          size="small"
        />
        <TextField
          label="Filter by Email"
          value={filterEmail}
          onChange={(e) => setFilterEmail(e.target.value)}
          size="small"
        />
        <TextField
          label="Filter by Lead Owner"
          value={filterLeadOwner}
          onChange={(e) => setFilterLeadOwner(e.target.value)}
          size="small"
        />
      </Box>
      <TableContainer>
        <Table>
          <TableHead sx={{ backgroundColor: "#e9e8e8" }}>
            <TableRow>
              <TableCell>Select</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Mobile Number</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Lead Owner</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedUsers.map((user) => (
              <TableRow key={user.uuid}>
                <TableCell>
                  <Checkbox
                    checked={user.is_allowed_for_bid_war}
                    onChange={(e) => handleCheckboxChange(e, user)}
                  />
                </TableCell>
                <TableCell>{`${user.firstName} ${user.lastName}`}</TableCell>
                <TableCell>{user.mobileNumber}</TableCell>
                <TableCell>{user.emailId}</TableCell>
                <TableCell>{user.leadowner}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, { value: -1, label: "All" }]}
        component="div"
        count={filteredUsers.length}
        rowsPerPage={rowsPerPage > 0 ? rowsPerPage : filteredUsers.length}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
};

export default Users;
