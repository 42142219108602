import React from "react";
import { alpha, Grid } from "@material-ui/core";
import { DataGrid, GridToolbar } from "@material-ui/data-grid";
import Paper from "@material-ui/core/Paper";
import {
  makeGETRequestOnUserService,
  makePOSTRequestOnUserService,
  makePUTRequestOnUserService,
} from "../../Api/api";
import { RFQOrdersList, NameAndSmestIDMapping } from "./interfaces";
import { Button, TextField } from "@material-ui/core";
import FullScreenDialog from "../../components/Dialog/FullScreenDialog";
import CreateRFQOrderSection from "./CreateRFQOrderSection";
import Autocomplete from "@material-ui/lab/Autocomplete";
import GenericDialog from "../../components/Dialog/GenericDialog";
import RFQAPICallLogs from "./RFQAPICallLogs";
import {
  jsonDataInitialState,
  dataGridCells,
  getFormattedAPICallLogs,
  convertToCamelCase,
} from "./utilities";
import { RouteComponentProps, withRouter } from "react-router-dom";
import toastr from "toastr";
import { Box, styled } from "@mui/material";

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  "& .row-pending": {
    backgroundColor: alpha(theme.palette.warning.light, 0.2),
  },
  "& .row-confirmed": {
    backgroundColor: alpha(theme.palette.secondary.light, 0.2),
  },
  "& .row-cancelled": {
    backgroundColor: alpha(theme.palette.error.light, 0.2),
  },
  "& .row-default": {
    backgroundColor: alpha(theme.palette.success.light, 0.2),
  },
}));

const RFQOrders: React.FC<RouteComponentProps> = (props) => {
  const [rfqOrders, setRfqOrders] = React.useState<RFQOrdersList[]>([]);
  const [showCreateOrderModal, setShowCreateOrderModal] =
    React.useState<boolean>(false);
  const [jsonData, setJsonData] = React.useState<any>(jsonDataInitialState);
  const [userNameAndUUIDMappings, setUserNameAndUUIDMappings] = React.useState<
    NameAndSmestIDMapping[]
  >([]);
  const [selectedUser, setSelectedUser] = React.useState<any>("");
  const [currentlySelectedRow, setCurrentlySelectedRow] =
    React.useState<any>(null);
  const [showRFQCallLogsDialog, setShowRFQCallLogsDialog] =
    React.useState<boolean>(false);
  const [orderRFQAPICallLogs, setOrderRFQAPICallLogs] = React.useState<
    object[]
  >([]);
  const [selectedOrderId, setSelectedOrderId] = React.useState<string>("");
  const [currentlyEditedOrderISIN, setCurrentlyEditedOrderISIN] =
    React.useState<string>("");
  const [showCancelOrderModal, setShowCancelOrderModal] =
    React.useState<boolean>(false);
  const [currentlySelectedRowForCancel, setCurrentlySelectedRowForCancel] =
    React.useState<any>(null);

  React.useEffect(() => {
    makeGETRequestOnUserService("/user/name/uuid/mapping/")
      .then((response: any) => {
        if (response && response.status === 200) {
          setUserNameAndUUIDMappings(response.data);
        }
      })
      .catch((error) => toastr.error(error));
  }, []);

  function getAllOrders() {
    makeGETRequestOnUserService("/order_management_v2/orders/")
      .then((resp) => {
        if (resp.status === 200) {
          setRfqOrders(
            convertToCamelCase<RFQOrdersList>([...resp.data, ...rfqOrders])
              .filter(
                (item) =>
                  JSON.parse(localStorage.getItem("loginUser")!)?.isSuperuser ||
                  JSON.parse(localStorage.getItem("loginUser")!)?.can_see_all ||
                  item.leadowner ===
                    JSON.parse(localStorage.getItem("loginUser")!)?.name
              )
              .sort((a, b) => {
                const dateA = new Date(a.insertedAt);
                const dateB = new Date(b.insertedAt);
                return dateB.getTime() - dateA.getTime();
              })
          );
        } else {
          toastr.error("Something went wrong");
        }
      })
      .catch((error) => toastr.error(error));
  }

  React.useEffect(() => {
    getAllOrders();
  }, []);

  function showFormattedName() {
    return userNameAndUUIDMappings.map(
      (mapping) => `${mapping.name}__${mapping.smestID}`
    );
  }

  function handleAddToRFQOnClick(order: any) {
    if (!order.isAddedOnRfq) {
      makePOSTRequestOnUserService("/order_management_v2/orders/add-to-rfq/", {
        uuid: order.uuid,
      })
        .then((resp: any) => {
          if (resp.status === 200) {
            toastr.success("Order Added to RFQ successfully");
            getAllOrders();
          } else toastr.error("Something was wrong");
        })
        .catch((error: any) => toastr.error(error));
    } else {
      // Call Accept RFQ Order API
      makePOSTRequestOnUserService(
        `/order_management_v2/orders/accept/${order.uuid}/`
      )
        .then((resp: any) => {
          if (resp.status === 200) {
            toastr.success("RFQ Order Accepted successfully");
            getAllOrders();
          } else toastr.error("Something was wrong");
        })
        .catch((error: any) => toastr.error(error));
    }
  }

  function handleViewRFQLogsClick(orderId: string) {
    makeGETRequestOnUserService(
      `/order_management_v2/orders/response_log/list/${orderId}/`
    )
      .then((resp: any) => {
        if (resp.data.length === 0) {
          toastr.warning("No RFQ API Call logs to show for this order");
        } else {
          setShowRFQCallLogsDialog(true);
          setOrderRFQAPICallLogs(getFormattedAPICallLogs(resp.data));
        }
      })
      .catch((error: any) => toastr.error(error));
  }

  function getOnlyEditableRows(order: any) {
    return {
      isin: order.isin,
      value: order.value,
      minimum_order_value: order.minimum_order_value,
      sell_yield: order.sell_yield,
      sell_price: order.sell_price,
    };
  }

  function handleCancelOrderOnClick(row: any) {
    if (row.orderStatus.toLowerCase() === "cancelled") {
      toastr.warning("This order is already cancelled.");
      return;
    }
    setShowCancelOrderModal(true);
    setCurrentlySelectedRowForCancel(row);
  }

  function handleOnEditIconClick(
    rowId: string,
    userId: string,
    isin: string,
    orderStatus: string
  ) {
    // if (orderStatus.toLowerCase() === "cancelled") {
    //   toastr.warning("Cancelled Orders cannot be edited.");
    //   return;
    // }
    props.history.push(`/rfq_orders/${rowId}`);
    // Check here for breaking changes.
  }

  function handleGoToNewRFQDetailsPage(rowId: string) {
    props.history.push(`/new_rfq_orders/${rowId}`);
  }

  function showInputProps() {
    if (!currentlySelectedRow) {
      // Case for create
      return (
        <Grid className="profileshowConntan">
          <div
            style={{
              height: "100vh",
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <CreateRFQOrderSection
              jsonData={jsonData}
              updateJsonData={(data: object) => setJsonData(data)}
            />
            <div>
              <Grid item xs={12}>
                <Autocomplete
                  id="free-solo-demo"
                  freeSolo={true}
                  options={showFormattedName()}
                  onChange={(event, enteredValue) => {
                    if (enteredValue) {
                      let splitted = enteredValue.split("__");
                      let filteredValue = userNameAndUUIDMappings.filter(
                        (mapping) => mapping.smestID === splitted[1]
                      )[0];
                      setSelectedUser(filteredValue.uuid);
                    }
                  }}
                  autoHighlight={true}
                  value={selectedUser}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select User..."
                      placeholder="Current format in dropdown is '<user name>__<smest_id>'"
                      margin="normal"
                      variant="outlined"
                      style={{ width: "450px" }}
                    />
                  )}
                />
              </Grid>
            </div>
          </div>
        </Grid>
      );
    } else {
      // Case for update
      return (
        <Grid className="profileshowConntan">
          <div
            style={{
              height: "100vh",
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <CreateRFQOrderSection
              jsonData={currentlySelectedRow}
              updateJsonData={(data: any) => {
                if (data.value != data.minimum_order_value) {
                  toastr.warning(
                    "Value and Minimum Order value should always be equal."
                  );
                }
                if (data.isin !== currentlyEditedOrderISIN) {
                  toastr.warning(
                    "You cannot edit ISIN. Please change it back to original value."
                  );
                } else {
                  setCurrentlySelectedRow(data);
                }
              }}
            />
            <div>
              <Grid item xs={12}>
                <Autocomplete
                  id="free-solo-demo"
                  freeSolo={true}
                  options={showFormattedName()}
                  onChange={(event, enteredValue) => {
                    if (enteredValue) {
                      let splitted = enteredValue.split("__");
                      let filteredValue = userNameAndUUIDMappings.filter(
                        (mapping) => mapping.smestID === splitted[1]
                      )[0];
                      setSelectedUser(filteredValue.uuid);
                    }
                  }}
                  autoHighlight={true}
                  value={selectedUser}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select User..."
                      placeholder="Current format in dropdown is '<user name>__<smest_id>'"
                      margin="normal"
                      variant="outlined"
                      style={{ width: "450px" }}
                    />
                  )}
                />
              </Grid>
            </div>
          </div>
        </Grid>
      );
    }
  }

  function handleCellClick(params: any) {
    if (params.field === "Edit") {
      handleOnEditIconClick(
        params.row.uuid,
        params.row.userId,
        params.row.isin,
        params.row.orderStatus
      );
    } else if (params.field === "AddToRFQ") {
      handleAddToRFQOnClick(params.row);
    } else if (params.field === "Status") {
      handleViewRFQLogsClick(params.row.uuid);
    } else if (params.field === "CancelOrder") {
      handleCancelOrderOnClick(params.row);
    } else if (params.field === "newPage") {
      handleGoToNewRFQDetailsPage(params.row.uuid);
    }
  }

  function handleAddNewRFQOrder(event: any) {
    event.preventDefault();
    setCurrentlySelectedRow(null);
    setJsonData(jsonDataInitialState);
    setShowCreateOrderModal(true);
  }

  function handleCancelOrderConfirmation(event: any) {
    event.preventDefault();
    makePUTRequestOnUserService(
      `order_management_v2/orders/cancel/${currentlySelectedRowForCancel.uuid}/`
    )
      .then((resp) => {
        if (
          resp.status === 200 &&
          resp?.data?.RFQModCanResponceList[0]?.ERRORCODE === "0"
        ) {
          toastr.success("Cancelled RFQ Order Successfully");
          setCurrentlySelectedRowForCancel(null);
          setShowCancelOrderModal(false);
        } else {
          toastr.error("Something went wrong. Unable to cancel order.");
          setCurrentlySelectedRowForCancel(null);
          setShowCancelOrderModal(false);
        }
      })
      .catch((error) => toastr.error(error));
  }

  function updateRFQOrder(event: any) {
    event.preventDefault();
    if (!selectedUser) {
      toastr.error("You need to select User ID");
    } else {
      jsonData.user = selectedUser;
      currentlySelectedRow.uuid = selectedOrderId;

      makePUTRequestOnUserService(
        "order_management_v2/orders/",
        currentlySelectedRow
      )
        .then((resp) => {
          if (
            resp.status === 200 &&
            resp.data.RFQModCanResponceList[0].ERRORCODE === "0"
          ) {
            toastr.success("RFQ Order Updated Successfully!");
            setJsonData(jsonDataInitialState);
            setShowCreateOrderModal(false);
            setCurrentlyEditedOrderISIN("");
            setSelectedOrderId("");
            setOrderRFQAPICallLogs([]);
            setCurrentlySelectedRow(null);
            setSelectedUser("");
          } else {
            toastr.error("Something Went Wrong");
          }
        })
        .catch((err) => toastr.error("Error", err.response.data.message));
    }
  }

  function createRFQOrder(event: any) {
    event.preventDefault();
    if (!selectedUser) {
      toastr.error("You need to select User ID");
    } else {
      jsonData.user = selectedUser;
      makePOSTRequestOnUserService("order_management_v2/orders/", jsonData)
        .then((resp) => {
          if (resp.status === 201) {
            toastr.success("RFQ order created successfully");
            setSelectedUser("");
            setShowCreateOrderModal(false);
            setJsonData(jsonDataInitialState);
          } else {
            toastr.error("Something went wrong");
          }
        })
        .catch((err) => toastr.error("Error", err.response.data.message));
    }
  }

  return (
    <Grid className="securityTypeDataGird">
      <Paper>
        <Grid style={{ margin: "20px 0", padding: "0 20px" }}>
          <Grid container alignItems="center" spacing={3}>
            <Grid item xs>
              <div style={{ fontSize: "36px", float: "left" }}>RFQ Orders</div>
            </Grid>
            <Grid item xs="auto">
              <Button
                className="btnClass"
                variant="outlined"
                // onClick={handleAddNewRFQOrder} // Old Implementation.
                color="primary"
                href="/create_rfq_order"
                style={{
                  float: "right",
                  margin: "25px 10px 15px",
                  cursor: "pointer",
                }}
              >
                Create RFQ Order
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Box sx={{ height: "100%", width: "100%", px: [2, 10] }}>
          <StyledDataGrid
            pagination
            components={{ Toolbar: GridToolbar }}
            rows={rfqOrders}
            columns={dataGridCells}
            onCellClick={handleCellClick}
            getRowId={(rfqOrder) => rfqOrder.uuid}
            autoHeight
            getRowClassName={(params) => {
              const { dealStatus } = params.row;
              if (dealStatus === "Pending") return "row-pending";
              if (dealStatus === "Confirmed") return "row-confirmed";
              if (dealStatus === "Cancelled") return "row-cancelled";
              return "row-default";
            }}
          />
        </Box>
      </Paper>
      {showCreateOrderModal ? (
        <FullScreenDialog
          show={showCreateOrderModal}
          handleClose={(event: any) => {
            event.preventDefault();
            setShowCreateOrderModal(false);
            setJsonData(jsonDataInitialState);
            setCurrentlyEditedOrderISIN("");
            setSelectedOrderId("");
            setOrderRFQAPICallLogs([]);
            setCurrentlySelectedRow(null);
            setSelectedUser("");
          }}
          showSaveButton={true}
          saveButtonText={!currentlySelectedRow ? "Create" : "Update"}
          onSumitSaveButton={(event: any) => {
            if (!currentlySelectedRow) {
              createRFQOrder(event);
            } else {
              updateRFQOrder(event);
            }
          }}
          inputProps={showInputProps()}
          heading={
            !currentlySelectedRow ? "Create RFQ Order" : "Update RFQ Order"
          }
        />
      ) : (
        ""
      )}
      {showRFQCallLogsDialog && (
        <FullScreenDialog
          show={showRFQCallLogsDialog}
          handleClose={(event: any) => {
            event.preventDefault();
            setShowRFQCallLogsDialog(false);
          }}
          showSaveButton={false}
          saveButtonText="View"
          onSumitSaveButton={() => {}}
          inputProps={<RFQAPICallLogs logs={orderRFQAPICallLogs} />}
          heading="IPO API Call Logs"
        />
      )}
      {showCancelOrderModal ? (
        <GenericDialog
          show={showCancelOrderModal}
          heading="Cancel Order"
          inputProps="Are you sure you want to cancel this order?"
          onClose={(event: any) => {
            setShowCancelOrderModal(false);
            setCurrentlySelectedRowForCancel(null);
          }}
          handleSubmit={handleCancelOrderConfirmation}
        />
      ) : (
        ""
      )}
    </Grid>
  );
};

export default withRouter(RFQOrders);
