import React, { useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  TablePagination,
  TextField,
  IconButton,
  Collapse,
  Button,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import toastr from "toastr";
import { makeDELETERequest } from "../../Api/api";

interface Bid {
  uuid: string;
  user_uuid: string;
  bid_value: string;
  settlement_amount: string;
  date_time: string;
  name_of_user: string;
  lead_owner_name: string;
  lead_owner_uuid: string | null;
}

interface BidByUserData {
  uuid: string;
  security_master: string;
  security_name: string;
  auction_yield: string;
  number_of_bonds: number;
  bid_settlement_amount: string;
  inserted_at: string;
  bids: Bid[];
}

const CollapsibleBidRow = ({ data }: { data: BidByUserData }) => {
  const [open, setOpen] = useState(false);
  const [filterNameOfUser, setFilterNameOfUser] = useState("");
  const [filterLeadOwnerName, setFilterLeadOwnerName] = useState("");
  const [filterBidValue, setFilterBidValue] = useState("");

  const [bidPage, setBidPage] = useState(0);
  const [bidRowsPerPage, setBidRowsPerPage] = useState(5);

  const filteredBids = useMemo(() => {
    return data.bids.filter((bid) => {
      return (
        (filterNameOfUser
          ? bid.name_of_user
              .toLowerCase()
              .includes(filterNameOfUser.toLowerCase())
          : true) &&
        (filterLeadOwnerName
          ? bid.lead_owner_name
              .toLowerCase()
              .includes(filterLeadOwnerName.toLowerCase())
          : true) &&
        (filterBidValue
          ? bid.bid_value.toLowerCase().includes(filterBidValue.toLowerCase())
          : true)
      );
    });
  }, [data.bids, filterNameOfUser, filterLeadOwnerName, filterBidValue]);

  const paginatedBids =
    bidRowsPerPage > 0
      ? filteredBids.slice(
          bidPage * bidRowsPerPage,
          bidPage * bidRowsPerPage + bidRowsPerPage
        )
      : filteredBids;

  const handleUnPushClick = () => {
    makeDELETERequest(`/bid/bids_delete/${data.uuid}/`)
      .then((resp) => {
        if (resp.status === 200) {
          toastr.success("Bid unpushed successfully.");
        } else {
          toastr.error("Failed to unpush bid.");
        }
      })
      .catch((error) => {
        console.error(error);
        toastr.error("Failed to unpush bid.");
      });
  };

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{data.security_master}</TableCell>
        <TableCell>{data.security_name}</TableCell>
        <TableCell>{data.auction_yield}</TableCell>
        <TableCell>{data.number_of_bonds}</TableCell>
        <TableCell>{data.bid_settlement_amount}</TableCell>
        <TableCell>
          <Button variant="contained" onClick={handleUnPushClick}>
            Unpush
          </Button>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Box sx={{ display: "flex", gap: 2, marginBottom: 1 }}>
                <TextField
                  label="Filter by Name of User"
                  value={filterNameOfUser}
                  onChange={(e) => setFilterNameOfUser(e.target.value)}
                  size="small"
                />
                <TextField
                  label="Filter by Lead Owner Name"
                  value={filterLeadOwnerName}
                  onChange={(e) => setFilterLeadOwnerName(e.target.value)}
                  size="small"
                />
                <TextField
                  label="Filter by Bid Value"
                  value={filterBidValue}
                  onChange={(e) => setFilterBidValue(e.target.value)}
                  size="small"
                />
              </Box>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Name of User</TableCell>
                    <TableCell>Lead Owner Name</TableCell>
                    <TableCell>Bid Value</TableCell>
                    <TableCell>Settlement Amount</TableCell>
                    <TableCell>Date Time</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginatedBids.map((bid, idx) => (
                    <TableRow key={idx}>
                      <TableCell>{bid.name_of_user}</TableCell>
                      <TableCell>{bid.lead_owner_name}</TableCell>
                      <TableCell>{bid.bid_value}</TableCell>
                      <TableCell>{bid.settlement_amount}</TableCell>
                      <TableCell>
                        {new Date(bid.date_time).toLocaleString()}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              {filteredBids.length > bidRowsPerPage && (
                <TablePagination
                  component="div"
                  count={filteredBids.length}
                  rowsPerPage={
                    bidRowsPerPage > 0 ? bidRowsPerPage : filteredBids.length
                  }
                  page={bidPage}
                  onPageChange={(event, newPage) => setBidPage(newPage)}
                  onRowsPerPageChange={(e) => {
                    setBidRowsPerPage(parseInt(e.target.value, 10));
                    setBidPage(0);
                  }}
                  rowsPerPageOptions={[5, 10, { value: -1, label: "All" }]}
                />
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const BidsByUsers = () => {
  const [data, setData] = useState<BidByUserData[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [ws, setWs] = useState<WebSocket | null>(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [filterSecurityMaster, setFilterSecurityMaster] = useState("");
  const [filterSecurityName, setFilterSecurityName] = useState("");

  const token = localStorage.getItem("AccessToken");
  const hasToken = Boolean(token);

  useEffect(() => {
    if (!hasToken) {
      toastr.error("No authentication token found.");
      return;
    }
    const websocket = new WebSocket(
      `wss://staging-admin-api.smestbonds.com/all_bids_by_users/?auth_token=${token}`
    );
    setWs(websocket);

    websocket.onopen = () => {
      toastr.success("WebSocket connected");
    };

    websocket.onmessage = (event) => {
      try {
        const response = JSON.parse(event.data);
        if (response.data) {
          setData(response.data);
        }
      } catch (error) {
        toastr.error("Error parsing websocket message");
      }
      setIsLoading(false);
    };

    websocket.onerror = (error) => {
      toastr.error("WebSocket error");
      toastr.error("WebSocket connection error");
      setIsLoading(false);
    };

    websocket.onclose = () => {
      toastr.success("WebSocket closed");
    };

    return () => {
      websocket.close();
    };
  }, [hasToken, token]);

  useEffect(() => {
    setPage(0);
  }, [filterSecurityMaster, filterSecurityName]);

  const filteredData = useMemo(() => {
    return data.filter((item) => {
      return (
        (filterSecurityMaster
          ? item.security_master
              .toLowerCase()
              .includes(filterSecurityMaster.toLowerCase())
          : true) &&
        (filterSecurityName
          ? item.security_name
              .toLowerCase()
              .includes(filterSecurityName.toLowerCase())
          : true)
      );
    });
  }, [data, filterSecurityMaster, filterSecurityName]);

  const paginatedData =
    rowsPerPage > 0
      ? filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      : filteredData;

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (!hasToken) {
    return <div>No authentication token found.</div>;
  }

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "300px",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <Box sx={{ p: 2, display: "flex", gap: 2, flexWrap: "wrap" }}>
        <TextField
          label="Filter by Security Master"
          value={filterSecurityMaster}
          onChange={(e) => setFilterSecurityMaster(e.target.value)}
          size="small"
        />
        <TextField
          label="Filter by Security Name"
          value={filterSecurityName}
          onChange={(e) => setFilterSecurityName(e.target.value)}
          size="small"
        />
      </Box>
      <TableContainer>
        <Table>
          <TableHead sx={{ backgroundColor: "#e9e8e8" }}>
            <TableRow>
              <TableCell />
              <TableCell>Security Master</TableCell>
              <TableCell>Security Name</TableCell>
              <TableCell>Auction Yield</TableCell>
              <TableCell>Number of Bonds</TableCell>
              <TableCell>Bid Settlement Amount</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedData.map((item) => (
              <CollapsibleBidRow key={item.uuid} data={item} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, { value: -1, label: "All" }]}
        component="div"
        count={filteredData.length}
        rowsPerPage={rowsPerPage > 0 ? rowsPerPage : filteredData.length}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
};

export default BidsByUsers;
