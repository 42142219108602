import React, { useMemo, useState } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import PlaylistAddRoundedIcon from "@mui/icons-material/PlaylistAddRounded";
import EditNoteRoundedIcon from "@mui/icons-material/EditNoteRounded";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import DoneAllRoundedIcon from "@mui/icons-material/DoneAllRounded";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import {
  makePOSTRequestOnUserService,
  makePUTRequestOnUserService,
} from "../../Api/api";
import toastr from "toastr";
import { StyledTextInput } from "../StyledComponents/StyledInputs";
import { ActionButtons, OrderDetailsInterface } from "./interfaces";
import moment from "moment";
import FormHelperText from "@mui/material/FormHelperText";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Transition from "../Transition";
import { StyledButton } from "./NewRFQOrderDetails";

export interface IModifyValues {
  value: number;
  sellYield: number;
  sellPrice: number;
  minimumOrderValue: number;
  principalAmount: number;
  modifiedAccruedInterest: number;
  stampDuty: number;
  totalConsiderationWithoutStampDuty: number;
  numberOfBonds: number;
  buyerClientCode: string;
  clientIFSC: string;
  clientBankAccountNumber: string;
  clientDPType: string;
  clientDPID: string;
  clientDPClientID: string;
  settlementDate: string;
  maturityDate: string;
  callDate: string;
  settlementType: string;
}

function isValueString(value: string) {
  return [
    "buyerClientCode",
    "clientIFSC",
    "clientBankAccountNumber",
    "clientDPType",
    "clientDPID",
    "clientDPClientID",
    "settlementDate",
    "maturityDate",
    "callDate",
  ].includes(value);
}

const RFQActionButtons = ({
  orderDetails,
  orderUUID,
  setLoading,
}: {
  orderDetails: OrderDetailsInterface;
  orderUUID: string;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [open, setOpen] = useState(false);
  const [internally, setInternally] = useState(false);
  const initialModifyValues = useMemo(
    () => ({
      value: orderDetails?.value!,
      sellYield: orderDetails?.sell_yield!,
      sellPrice: orderDetails?.sell_price!,
      minimumOrderValue: orderDetails?.minimum_order_value!,
      principalAmount: orderDetails?.principal_amount!,
      modifiedAccruedInterest: orderDetails?.modified_accrued_interest!,
      stampDuty: orderDetails?.stamp_duty!,
      totalConsiderationWithoutStampDuty: orderDetails?.total_consideration!,
      numberOfBonds: orderDetails?.no_of_bonds!,
      buyerClientCode: orderDetails?.buyer_client_name!,
      clientIFSC: orderDetails?.initiator_ifsc!,
      clientBankAccountNumber: orderDetails?.initiator_bank_account_number!,
      clientDPType: orderDetails?.initiator_dp_type!,
      clientDPID: orderDetails?.initiator_dpid!,
      clientDPClientID: orderDetails?.initiator_client_id!,
      settlementDate: moment(
        orderDetails?.settlement_date!,
        "MMM DD, YYYY"
      ).format("YYYY-MM-DD"),
      maturityDate: orderDetails?.last_maturity_date
        ? moment(orderDetails?.last_maturity_date, "DD/MM/YYYY").format(
            "YYYY-MM-DD"
          )
        : "",
      callDate: orderDetails?.last_call_date
        ? moment(orderDetails?.last_call_date, "DD/MM/YYYY").format(
            "YYYY-MM-DD"
          )
        : "",
      settlementType: orderDetails?.settlement_type!,
    }),
    [orderDetails]
  );
  const [modifyValues, setModifyValues] = useState(initialModifyValues);

  const handleAddRFQ = () => {
    makePOSTRequestOnUserService("order_management_v2/orders/add-to-rfq/", {
      uuid: orderUUID,
      admin_name: JSON.parse(localStorage.getItem("loginUser")!)?.name,
    })
      .then((response) => {
        if (response && response.status === 200) {
          toastr.success("Quote Added Successfully!");
          setLoading(true);
        }
      })
      .catch((error) => toastr.error(error));
  };
  const handleAcceptRFQ = () => {
    makePOSTRequestOnUserService(
      `/order_management_v2/orders/accept_order/${orderUUID}/`,
      {
        ModAcrInt: orderDetails?.modified_accrued_interest,
        TotalConsideration: orderDetails?.total_consideration,
        admin_name: JSON.parse(localStorage.getItem("loginUser")!)?.name,
      }
    )
      .then((response) => {
        if (response && response.status === 200) {
          const { data } = response;
          if (data && data.RFQQuoteAcceptResponseList[0]?.message) {
            toastr.success(data.RFQQuoteAcceptResponseList[0]?.message);
          } else {
            toastr.success("Quote Accepted Successfully!");
          }
          setLoading(true);
        }
      })
      .catch((error) => toastr.error(error));
  };
  const handleModify = () => {
    makePUTRequestOnUserService(`/order_management_v2/orders/`, {
      uuid: orderUUID,
      isin: orderDetails?.isin,
      value: modifyValues.value,
      minimum_order_value: modifyValues.minimumOrderValue,
      sell_yield: modifyValues.sellYield,
      sell_price: modifyValues.sellPrice,
      principal_amount: modifyValues.principalAmount,
      modified_accrued_interest: modifyValues.modifiedAccruedInterest,
      stamp_duty: modifyValues.stampDuty,
      total_consideration: modifyValues.totalConsiderationWithoutStampDuty,
      admin_name: JSON.parse(localStorage.getItem("loginUser")!)?.name,
    })
      .then((response) => {
        if (response && response.status === 200) {
          toastr.success("RFQ Modified Successfully");
          setLoading(true);
          setOpen(false);
        }
      })
      .catch((error) => toastr.error(error));
  };
  const handleModifyInternally = () => {
    makePUTRequestOnUserService(
      `/order_management_v2/rfq_modified_internally/`,
      {
        uuid: orderUUID,
        isin: orderDetails?.isin,
        value: modifyValues.value,
        minimum_order_value: modifyValues.minimumOrderValue,
        sell_yield: modifyValues.sellYield,
        sell_price: modifyValues.sellPrice,
        principal_amount: modifyValues.principalAmount,
        modified_accrued_interest: modifyValues.modifiedAccruedInterest,
        stamp_duty: modifyValues.stampDuty,
        total_consideration: modifyValues.totalConsiderationWithoutStampDuty,
        buyer_client_name: modifyValues.buyerClientCode,
        admin_name: JSON.parse(localStorage.getItem("loginUser")!)?.name,
        no_of_bonds: modifyValues.numberOfBonds,
        initiator_ifsc: modifyValues.clientIFSC,
        initiator_bank_account_number: modifyValues.clientBankAccountNumber,
        initiator_dp_type: modifyValues.clientDPType,
        initiator_dpid: modifyValues.clientDPID,
        initiator_client_id: modifyValues.clientDPClientID,
        settlement_date: moment(
          modifyValues.settlementDate,
          "YYYY-MM-DD"
        ).format("MMM DD,YYYY"),
        last_maturity_date: modifyValues.maturityDate
          ? moment(modifyValues.maturityDate, "YYYY-MM-DD").format("DD/MM/YYYY")
          : "",
        last_call_date: modifyValues.callDate
          ? moment(modifyValues.callDate, "YYYY-MM-DD").format("DD/MM/YYYY")
          : "",
        settlement_type: modifyValues.settlementType,
      }
    )
      .then((response) => {
        if (response && response.status === 200) {
          toastr.success("RFQ Modified Internally");
          setOpen(false);
          setInternally(false);
          setLoading(true);
        }
      })
      .catch((error) => toastr.error(error));
  };
  const handleCancelRFQ = () => {
    makePUTRequestOnUserService(
      `/order_management_v2/orders/cancel/${orderUUID}/`,
      { admin_name: JSON.parse(localStorage.getItem("loginUser")!)?.name }
    )
      .then((response) => {
        if (response && response.status === 200) {
          toastr.success("RFQ Cancelled Successfully");
          setLoading(true);
        }
      })
      .catch((error) => toastr.error(error));
  };
  const handleProposeDeal = () => {
    makePOSTRequestOnUserService(`order_management_v2/rfq_deal_propose/`, {
      uuid: orderUUID,
      admin_name: JSON.parse(localStorage.getItem("loginUser")!)?.name,
    })
      .then((response) => {
        if (response && response.status === 200) {
          const { data } = response;
          if (data && data.RFQPROPOSEDEALRESPONSE[0]?.message) {
            toastr.success(data.RFQPROPOSEDEALRESPONSE[0]?.message);
          } else {
            toastr.success("DEAL PROPOSED SUCCESSFULLY!");
          }
          setLoading(true);
        }
      })
      .catch((error) => toastr.error(error));
  };
  const handleApproveDeal = () => {
    makePOSTRequestOnUserService(`order_management_v2/rfq_deal_approve/`, {
      uuid: orderUUID,
      admin_name: JSON.parse(localStorage.getItem("loginUser")!)?.name,
    })
      .then((response) => {
        if (response && response.status === 200) {
          const { data } = response;
          if (data && data.BROKER_RFQDEALAPPROVERESPONSE[0]?.message) {
            toastr.success(data.BROKER_RFQDEALAPPROVERESPONSE[0]?.message);
          } else {
            toastr.success("TRADE APPROVED AND SENT FOR SETTLEMENT!");
          }
          setLoading(true);
        }
      })
      .catch((error) => toastr.error(error));
  };

  const rfqActionButtons: ActionButtons[] = useMemo(
    () => [
      {
        name: "Add RFQ Quote",
        color: "info",
        icon: <PlaylistAddRoundedIcon />,
        disabled: orderDetails?.is_added_on_rfq as boolean,
        helperText: orderDetails?.is_added_on_rfq
          ? "Quote Added Successfully!"
          : "",
        handleClick: () => handleAddRFQ(),
      },
      {
        name: "Accept RFQ Quote",
        color: "success",
        icon: <DoneAllRoundedIcon />,
        disabled: !orderDetails?.is_added_on_rfq
          ? true
          : orderDetails?.order_status === "Confirmed" ||
            orderDetails?.order_status === "Cancelled"
          ? true
          : false,
        helperText:
          orderDetails?.order_status === "Confirmed" ||
          orderDetails?.order_status === "Cancelled"
            ? `Quote ${orderDetails?.order_status} Successfully!`
            : "",
        handleClick: () => handleAcceptRFQ(),
      },
      {
        name: "Modify Internally",
        color: "warning",
        icon: <EditNoteRoundedIcon />,
        disabled:
          orderDetails?.order_status === "Cancelled" ||
          orderDetails?.order_status === "Confirmed"
            ? true
            : false,
        helperText: "",
        handleClick: () => {
          setModifyValues(initialModifyValues);
          setOpen(true);
          setInternally(true);
        },
      },
      {
        name: "Modify RFQ Quote",
        color: "warning",
        icon: <EditNoteRoundedIcon />,
        disabled: !orderDetails?.is_added_on_rfq
          ? true
          : orderDetails?.order_status === "Cancelled" ||
            orderDetails?.order_status === "Confirmed"
          ? true
          : false,
        helperText: "",
        handleClick: () => {
          setModifyValues(initialModifyValues);
          setOpen(true);
        },
      },
      {
        name: "Cancel RFQ Quote",
        color: "error",
        icon: <DeleteForeverRoundedIcon />,
        disabled: !orderDetails?.is_added_on_rfq
          ? true
          : orderDetails?.order_status === "Cancelled" ||
            orderDetails?.order_status === "Confirmed"
          ? true
          : false,
        helperText:
          orderDetails?.order_status === "Cancelled" ||
          orderDetails?.order_status === "Confirmed"
            ? `RFQ ${orderDetails?.order_status} Successfully`
            : "",
        handleClick: () => handleCancelRFQ(),
      },
      {
        name: "Propose Deal",
        color: "success",
        icon: <DoneAllRoundedIcon />,
        handleClick: () => handleProposeDeal(),
      },
      {
        name: "Approve Deal",
        color: "success",
        icon: <DoneAllRoundedIcon />,
        handleClick: () => handleApproveDeal(),
      },
    ],
    [orderDetails]
  );
  return (
    <>
      <Grid container spacing={2} mt={4}>
        {rfqActionButtons.map((button, index) => (
          <Grid item key={index} xs={6} sm={4} md={2.4}>
            <StyledButton
              startIcon={button.icon}
              color={button.color}
              onClick={button.handleClick}
              disabled={button.disabled}
            >
              {button.name}
            </StyledButton>
            <Typography
              sx={{
                color: "#45E142",
                fontSize: 12,
                textAlign: "center",
                mt: 1,
              }}
            >
              {button.helperText}
            </Typography>
          </Grid>
        ))}
      </Grid>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          setOpen(false);
          internally && setInternally(false);
        }}
        maxWidth="sm"
        fullWidth
      >
        <DialogContent>
          <Grid container spacing={1}>
            {Object.keys(initialModifyValues).map((item, index) => (
              <Grid item xs={6} key={index}>
                <FormControl fullWidth variant="standard">
                  <InputLabel shrink htmlFor={item}>
                    {item
                      .replace(/([A-Z])/g, " $1")
                      .replace(/^./, (str) => str.toUpperCase())}
                  </InputLabel>
                  <StyledTextInput
                    type={
                      ["settlementDate", "maturityDate", "callDate"].includes(
                        item
                      )
                        ? "date"
                        : isValueString(item)
                        ? "text"
                        : "number"
                    }
                    id={`${item}_${index}`}
                    name={item}
                    value={modifyValues[item as keyof IModifyValues]}
                    {...(item === "settlementType" && {
                      onInput: (e) => {
                        const allowedValues = ["0", "1", "2"];
                        const inputValue = (e.target as HTMLInputElement).value;
                        if (!allowedValues.includes(inputValue)) {
                          (e.target as HTMLInputElement).value = "";
                        }
                      },
                    })}
                    onChange={(event) => {
                      const value = event.target.value;
                      setModifyValues((prev) => ({
                        ...prev,
                        [item as keyof IModifyValues]: isValueString(item)
                          ? value
                          : parseFloat(value),
                      }));
                    }}
                  />
                  {[
                    "totalConsiderationWithoutStampDuty",
                    "settlementDate",
                    "maturityDate",
                    "callDate",
                  ].includes(item) && (
                    <FormHelperText
                      sx={{
                        color: "#45E142",
                        fontSize: 12,
                        ml: 1,
                      }}
                    >
                      {item === "totalConsiderationWithoutStampDuty"
                        ? `With stamp duty ${
                            modifyValues.totalConsiderationWithoutStampDuty +
                            modifyValues.stampDuty
                          }`
                        : "Pattern: DD-MM-YYYY"}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() =>
              internally ? handleModifyInternally() : handleModify()
            }
          >
            Modify
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RFQActionButtons;
