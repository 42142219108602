import React, { useContext } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import LoginSection from "../components/LoginSection";
import NewDashboard from "../pages/Dashboard/NewDashboard";
import { appContext } from "../App";
import Header from "../components/Header.js";
import HolidayMaster from "../pages/HolidayMaster";
import IssuerMaster from "../pages/IssuerMaster";
import RatingOrganizationMaster from "../pages/RatingOrganizationMaster";
import SecurityMaster from "../pages/SecurityMaster";
import SecurityType from "../pages/SecurityType";
import Quotes from "../pages/Quotes";
import Users from "../pages/Users/Users";
import DebarredUsers from "../pages/Users/DebarredUsers";
import AdminUserDashboard from "../pages/AdminUsers/AdminUserDashboard";
import AdminUserLogin from "../pages/AdminUsers/AdminUserLogin";
import Subscriptions from "../components/Subscriptions/Subscriptions";
import Newsletter from "../components/Newsletter";
import Orders from "../pages/Orders";
import OrderTable from "../components/Orders/OrderTable";
import PodcastMaster from "../pages/PodcastMaster/PodcastMaster";
import Marketers from "../pages/Marketers/Marketers";
import LoyaltyPointsHistory from "../pages/LoyaltyPointsHistory/LoyaltyPointsHistory";
import ActivityLogs from "../pages/ActivityLogs";
import ProfilePage from "../pages/ProfilePage";
import WhatsappCampaign from "../pages/WhatsappCampaign/WhatsappCampaign";
import AllWhatsappMessages from "../pages/WhatsappCampaign/AllWhatsappMessages";
import UserSpecificCategories from "../pages/WhatsappCampaign/UserSpecificCategories";
import EmailVerifications from "../pages/EmailVerifications";
import CashFlowRecords from "../pages/CashFlowRecords";
import Notifications from "../pages/Notifications";
import WhatsappTemplates from "../pages/WhatsappCampaign/WhatsappTemplates";
import BlacklistedUsers from "../pages/BlacklistedUsers/BlacklistedUsers";
import BSEIPOs from "../pages/IPO/BSEIPOs";
import IPOSecurityMaster from "../pages/IPO/IPOSecurityMaster";
import IPOQuotes from "../pages/IPO/IPOQuotes";
import RFQOrders from "../components/RFQOrders/RFQOrders";
import CreateRFQOrder from "../components/RFQOrders/CreateRFQOrder";
import OpenDebtIssues from "../pages/IPO/OpenDebtIssues";
import IPOOrders from "../pages/IPO/IPOOrders";
import GetCategoriesDemand from "../pages/IPO/GetCategoriesDemand";
import IPOBids from "../pages/IPO/IPOBids";
import IssueTime from "../pages/IPO/IssueTime";
import CreateCashflowRecord from "../pages/CreateCashflowRecords";
import RFQOrderDetails from "../components/RFQOrders/RFQOrderDetails";
import NewRFQOrderDetails from "../components/RFQOrders/NewRFQOrderDetails";
import DownloadPermissions from "../pages/Users/DownloadPermissions";
import UpdateUCC from "../components/Users/UpdateUCC";
import YieldCalculator from "../pages/YieldCalculator";
import BidWars from "../pages/BidWars";
import PriceYieldHistory from "../pages/PriceYieldHistory";
import NotFoundPage from "../pages/NotFound";

const ProtectedRoute = ({
  component: Component,
  permissions,
  authUser,
  ...rest
}) => {
  let permissionsArray;

  if (typeof permissions === "string") {
    try {
      permissionsArray = JSON.parse(permissions);
    } catch (error) {
      console.error("Error parsing JSON string:", error);
    }
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        authUser.isSuperuser ||
        permissionsArray.includes(rest.permissionRequired) ? (
          <Component {...props} />
        ) : (
          <Redirect to="/not-found" />
        )
      }
    />
  );
};

const MainRouter = (props) => {
  const { authUser, setAuthUser } = useContext(appContext);
  const currentPermissions = localStorage.getItem("permissions");
  return (
    <Router>
      <Header />
      <div style={{ minHeight: 64 }} />
      <>
        <Route exact path="/" component={LoginSection} />
        <Route exact path="/admin_user/login" component={AdminUserLogin} />

        <Route exact path="/not-found" component={NotFoundPage} />

        {authUser && authUser.uuid && (
          <Switch>
            <Route exact path="/dashboard" component={NewDashboard} />

            <ProtectedRoute
              exact
              path="/holiday_master"
              component={HolidayMaster}
              permissions={currentPermissions}
              permissionRequired="Holiday Master"
              authUser={authUser}
            />

            <ProtectedRoute
              exact
              path="/issuer_master"
              component={IssuerMaster}
              authUser={authUser}
              permissions={currentPermissions}
              permissionRequired="Issuer Master"
            />

            <ProtectedRoute
              exact
              path="/users"
              component={Users}
              authUser={authUser}
              permissions={currentPermissions}
              permissionRequired="Users"
            />

            <ProtectedRoute
              exact
              path="/debarred_users"
              component={DebarredUsers}
              authUser={authUser}
              permissions={currentPermissions}
              permissionRequired="Users"
            />

            <ProtectedRoute
              exact
              path="/download_permissions"
              component={DownloadPermissions}
              authUser={authUser}
              permissions={currentPermissions}
              permissionRequired="Users"
            />

            <ProtectedRoute
              exact
              path="/bid_wars"
              component={BidWars}
              authUser={authUser}
              permissions={currentPermissions}
              permissionRequired="Users"
            />

            <ProtectedRoute
              exact
              path="/price_yield_history"
              component={PriceYieldHistory}
              authUser={authUser}
              permissions={currentPermissions}
              permissionRequired="Orders"
            />

            <ProtectedRoute
              exact
              path="/activity_logs"
              authUser={authUser}
              component={ActivityLogs}
              permissions={currentPermissions}
              permissionRequired="Activity Logs"
            />

            <ProtectedRoute
              exact
              path="/loyalty_points_history"
              component={LoyaltyPointsHistory}
              authUser={authUser}
              permissions={currentPermissions}
              permissionRequired="Loyalty Points History"
            />

            <ProtectedRoute
              exact
              path="/cashflow_record/:action/:isin/:uuid"
              component={CreateCashflowRecord}
              authUser={authUser}
              permissions={currentPermissions}
              permissionRequired="Security Master"
            />

            <ProtectedRoute
              exact
              path="/ipo_security_master"
              authUser={authUser}
              component={IPOSecurityMaster}
              permissions={currentPermissions}
              permissionRequired="IPO"
            />

            <ProtectedRoute
              exact
              path="/rating_organization_master"
              component={RatingOrganizationMaster}
              authUser={authUser}
              permissions={currentPermissions}
              permissionRequired="Rating Organization Master"
            />

            <Route
              exact
              path="/blacklisted_users"
              component={BlacklistedUsers}
            />

            <ProtectedRoute
              exact
              path="/security_type"
              authUser={authUser}
              component={SecurityType}
              permissions={currentPermissions}
              permissionRequired="Security Type"
            />

            <ProtectedRoute
              exact
              path="/security_master"
              authUser={authUser}
              component={SecurityMaster}
              permissions={currentPermissions}
              permissionRequired="Security Master"
            />

            <ProtectedRoute
              exact
              path="/quotes"
              component={Quotes}
              authUser={authUser}
              permissions={currentPermissions}
              permissionRequired="Quotes"
            />

            <ProtectedRoute
              exact
              path="/create_rfq_order"
              component={CreateRFQOrder}
              authUser={authUser}
              permissions={currentPermissions}
              permissionRequired="Create Orders"
            />

            <ProtectedRoute
              exact
              path="/rfq_orders"
              component={RFQOrders}
              permissions={currentPermissions}
              authUser={authUser}
              permissionRequired="Orders"
            />

            <ProtectedRoute
              exact
              path="/new_rfq_orders/:orderUUID"
              component={NewRFQOrderDetails}
              authUser={authUser}
              permissions={currentPermissions}
              permissionRequired="Orders"
            />

            <ProtectedRoute
              exact
              path="/update_ucc/:userUUID"
              component={UpdateUCC}
              authUser={authUser}
              permissions={currentPermissions}
              permissionRequired="Users"
            />

            <ProtectedRoute
              exact
              path="/rfq_orders/:orderUUID"
              component={RFQOrderDetails}
              authUser={authUser}
              permissions={currentPermissions}
              permissionRequired="Orders"
            />

            <ProtectedRoute
              exact
              path="/yield-calculator"
              component={YieldCalculator}
              authUser={authUser}
              permissions={currentPermissions}
              permissionRequired="Yield Calculator"
            />

            <ProtectedRoute
              exact
              path="/orders"
              authUser={authUser}
              component={Orders}
              permissions={currentPermissions}
              permissionRequired="Orders"
            />

            <ProtectedRoute
              exact
              path="/orders/:orderUUID"
              authUser={authUser}
              component={OrderTable}
              permissions={currentPermissions}
              permissionRequired="Orders"
            />

            <Route exact path="/profile/:uuid" component={ProfilePage} />
            <Route
              exact
              path="/email_verifications"
              component={EmailVerifications}
            />
            <Route exact path="/cashflow_records" component={CashFlowRecords} />
            <Route exact path="/notifications" component={Notifications} />

            <ProtectedRoute
              exact
              path="/admin_users"
              component={AdminUserDashboard}
              permissions={currentPermissions}
              permissionRequired=""
              authUser={authUser}
            />

            <ProtectedRoute
              exact
              path="/open_debt_issues"
              component={OpenDebtIssues}
              permissions={currentPermissions}
              permissionRequired="IPO"
              authUser={authUser}
            />

            <ProtectedRoute
              exact
              path="/ipo_orders"
              component={IPOOrders}
              permissions={currentPermissions}
              permissionRequired="IPO"
              authUser={authUser}
            />

            <ProtectedRoute
              exact
              path="/get_categories_demand"
              component={GetCategoriesDemand}
              permissions={currentPermissions}
              permissionRequired="IPO"
              authUser={authUser}
            />

            <ProtectedRoute
              exact
              path="/ipo_bids"
              component={IPOBids}
              permissions={currentPermissions}
              permissionRequired="IPO"
              authUser={authUser}
            />

            <ProtectedRoute
              exact
              path="/issue_time"
              component={IssueTime}
              permissions={currentPermissions}
              permissionRequired="IPO"
              authUser={authUser}
            />

            <ProtectedRoute
              exact
              path="/bse_ipos"
              component={BSEIPOs}
              permissions={currentPermissions}
              permissionRequired="IPO"
              authUser={authUser}
            />

            <ProtectedRoute
              exact
              path="/ipo_quotes"
              component={IPOQuotes}
              permissions={currentPermissions}
              permissionRequired="IPO"
              authUser={authUser}
            />

            <ProtectedRoute
              exact
              path="/subscriptions"
              component={Subscriptions}
              permissions={currentPermissions}
              permissionRequired=""
              authUser={authUser}
            />

            <ProtectedRoute
              exact
              path="/newsletter"
              component={Newsletter}
              permissions={currentPermissions}
              permissionRequired=""
              authUser={authUser}
            />

            <ProtectedRoute
              exact
              path="/podcast_master"
              component={PodcastMaster}
              permissions={currentPermissions}
              permissionRequired=""
              authUser={authUser}
            />

            <ProtectedRoute
              exact
              path="/whatsapp_campaign"
              component={WhatsappCampaign}
              permissions={currentPermissions}
              permissionRequired="Whatsapp Campaign"
              authUser={authUser}
            />
            <ProtectedRoute
              exact
              path="/whatsapp_templates"
              component={WhatsappTemplates}
              permissions={currentPermissions}
              permissionRequired="Whatsapp Campaign"
              authUser={authUser}
            />
            <ProtectedRoute
              exact
              path="/whatsapp_messages/all"
              component={AllWhatsappMessages}
              permissions={currentPermissions}
              permissionRequired="Whatsapp Campaign"
              authUser={authUser}
            />
            <ProtectedRoute
              exact
              path="/whatsapp_campaign/categories/:userId"
              component={UserSpecificCategories}
              permissions={currentPermissions}
              permissionRequired="Whatsapp Campaign"
              authUser={authUser}
            />
          </Switch>
        )}
      </>
    </Router>
  );
};

export default MainRouter;
