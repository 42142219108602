import React, { useEffect, useMemo, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  CircularProgress,
  TablePagination,
  TextField,
  Box,
} from "@mui/material";
import {
  makeGETRequestOnUserService,
  makePUTRequestOnUserService,
} from "../../Api/api";
import toastr from "toastr";

export interface User {
  last_login: null;
  uuid: string;
  firstName: string;
  lastName: string;
  gender: string | null;
  existingInvestor: string | null;
  agreeToTerms: boolean;
  investedBefore: boolean;
  lookingToInvest: string;
  maritalStatus: string;
  fathersName: null | string;
  maidenName: null | string;
  occupation: null | string;
  hufPanCardName: null | string;
  dateOfIncorporationHuf: Date | null;
  deedOfDeclarationDocName: null | string;
  areHufDocsUploaded: boolean;
  nameOfKarta: null | string;
  annualIncome: string;
  accountType: string;
  emailId: string;
  mobileNumber: string;
  address: null | string;
  city: null | string;
  pincode: null | string;
  state: null | string;
  dateOfBirth: Date | null;
  isMobileVerified: boolean;
  isEmailVerified: boolean;
  isPrototypeUser: boolean;
  gstin: string;
  password: string;
  isPanCardSubmitted: boolean;
  isAddressProofSubmitted: boolean;
  isChequeSubmitted: boolean;
  isPennyDropComplete: boolean;
  isDematProofSubmitted: boolean;
  isESignComplete: boolean;
  areKYCDetailsVerified: boolean;
  areNomineeDetailsSubmitted: boolean;
  areNomineeDetailsVerified: boolean;
  isRiskProfileCompleted: boolean;
  birthCountry: string | null;
  nationality: string | null;
  hasPoliticalExposure: boolean;
  isParentAccount: boolean;
  isCIBRegistrationComplete: boolean;
  isDeleted: boolean;
  isAdmin: boolean;
  isStaff: boolean;
  isActive: boolean;
  isSuperuser: boolean;
  can_download: boolean;
  are_personal_details_submitted: boolean;
  insertedAt: Date;
  updatedAt: Date;
  isBlocked: boolean;
  smestID: string;
  referral_code: string;
  loyalty_point_balance: number;
  canSendWhatsappUpdates: boolean;
  isRFQRegistrationComplete: boolean;
  middleName: null;
  motherName: null | string;
  photopart: null | string;
  signpart: null | string;
  leads_uuid_id: null | string;
  profile_image_name: null | string;
  call_alert_api_timestamp: Date | null;
  ucc_external_id: string;
  leadsstatus: string;
  leadsremarks: string;
  leadstofollow_up: string;
  kyc_markedcompleted_at: Date | null;
  hufPanCardPlainTextPassword: null | string;
  hufDeedOfDeclarationPlainTextPassword: null | string;
  beneficiaryName: string;
  leadowner: null | string;
  source: null;
  is_allowed_for_bid_war: boolean;
  is_bidwar_invitation_sent: boolean;
}

const DownloadPermissions = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [filterName, setFilterName] = useState("");
  const [filterMobile, setFilterMobile] = useState("");
  const [filterEmail, setFilterEmail] = useState("");
  const [filterLeadOwner, setFilterLeadOwner] = useState("");

  useEffect(() => {
    setIsLoading(true);
    makeGETRequestOnUserService("/user/user_list/")
      .then((resp) => {
        if (resp.status === 200) {
          setUsers(resp.data);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        toastr.error("Failed to fetch user list.");
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    setPage(0);
  }, [filterName, filterMobile, filterEmail, filterLeadOwner]);

  const filteredUsers = useMemo(() => {
    return users.filter((user) => {
      const fullName = `${user.firstName} ${user.lastName}`.toLowerCase();
      const mobile = user.mobileNumber.toLowerCase();
      const email = user.emailId.toLowerCase();
      const leadOwner = user.leadowner ? user.leadowner.toLowerCase() : "";
      return (
        (filterName ? fullName.includes(filterName.toLowerCase()) : true) &&
        (filterMobile ? mobile.includes(filterMobile.toLowerCase()) : true) &&
        (filterEmail ? email.includes(filterEmail.toLowerCase()) : true) &&
        (filterLeadOwner
          ? leadOwner.includes(filterLeadOwner.toLowerCase())
          : true)
      );
    });
  }, [users, filterName, filterMobile, filterEmail, filterLeadOwner]);

  // Handle checkbox changes and update the backend.
  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    user: User
  ) => {
    const newValue = event.target.checked;
    // Optimistically update UI
    setUsers((prevUsers) =>
      prevUsers.map((u) =>
        u.uuid === user.uuid ? { ...u, can_download: newValue } : u
      )
    );

    makePUTRequestOnUserService("user/update/admin_panel/", {
      uuid: user.uuid,
      can_download: newValue,
      mobileNumber: user.mobileNumber,
      emailId: user.emailId,
    })
      .then((resp) => {
        if (resp.status === 200) {
          toastr.success("User updated successfully");
        } else {
          toastr.error("Failed to update user");
          // Revert change if API call fails
          setUsers((prevUsers) =>
            prevUsers.map((u) =>
              u.uuid === user.uuid ? { ...u, can_download: !newValue } : u
            )
          );
        }
      })
      .catch((error) => {
        toastr.error("Failed to update user");
        // Revert change on error
        setUsers((prevUsers) =>
          prevUsers.map((u) =>
            u.uuid === user.uuid ? { ...u, can_download: !newValue } : u
          )
        );
      });
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedUsers =
    rowsPerPage > 0
      ? filteredUsers.slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage
        )
      : filteredUsers;

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "300px",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <Box
      sx={{
        maxWidth: "90%",
        mx: "auto",
        zoom: 0.8,
        borderRadius: 3,
        border: "1px solid grey",
        my: 2,
      }}
    >
      <Box sx={{ p: 2, display: "flex", gap: 2, flexWrap: "wrap" }}>
        <TextField
          label="Filter by Name"
          value={filterName}
          onChange={(e) => setFilterName(e.target.value)}
          size="small"
        />
        <TextField
          label="Filter by Mobile Number"
          value={filterMobile}
          onChange={(e) => setFilterMobile(e.target.value)}
          size="small"
        />
        <TextField
          label="Filter by Email"
          value={filterEmail}
          onChange={(e) => setFilterEmail(e.target.value)}
          size="small"
        />
        <TextField
          label="Filter by Lead Owner"
          value={filterLeadOwner}
          onChange={(e) => setFilterLeadOwner(e.target.value)}
          size="small"
        />
      </Box>
      <TableContainer>
        <Table>
          <TableHead sx={{ backgroundColor: "#e9e8e8" }}>
            <TableRow>
              <TableCell>Select</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Mobile Number</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Lead Owner</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedUsers.map((user) => (
              <TableRow key={user.uuid}>
                <TableCell>
                  <Checkbox
                    checked={user.can_download}
                    onChange={(e) => handleCheckboxChange(e, user)}
                  />
                </TableCell>
                <TableCell>{`${user.firstName} ${user.lastName}`}</TableCell>
                <TableCell>{user.mobileNumber}</TableCell>
                <TableCell>{user.emailId}</TableCell>
                <TableCell>{user.leadowner}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, { value: -1, label: "All" }]}
        component="div"
        count={filteredUsers.length}
        rowsPerPage={rowsPerPage > 0 ? rowsPerPage : filteredUsers.length}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
};

export default DownloadPermissions;
