import React, { useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  TablePagination,
  TextField,
} from "@mui/material";
import toastr from "toastr";
import moment from "moment";
import { makePOSTRequestOnUserService } from "../../Api/api";

export interface HistoryData {
  isin_no: string;
  issuer_name: string;
  last_traded_price: number;
  last_trade_yield: number;
  trade_date: string;
  turnover_rs_in_lakhs: number;
}

const PriceYieldHistory = () => {
  const [historyData, setHistoryData] = useState<HistoryData[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [filterIsinno, setFilterIsinno] = useState("");
  const [filterIssuersName, setFilterIssuersName] = useState("");
  const [filterTradeDate, setFilterTradeDate] = useState(
    moment().format("YYYY-MM-DD")
  );

  useEffect(() => {
    setIsLoading(true);
    makePOSTRequestOnUserService("/order_management_v2/filter_debt_data/", {
      date: moment(filterTradeDate, "YYYY-MM-DD").format("DD-MM-YYYY"),
    })
      .then((resp) => {
        if (resp.status === 200) {
          setHistoryData(resp.data.data);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        toastr.error("Failed to fetch history data.");
        setIsLoading(false);
      });
  }, [filterTradeDate]);

  const filteredData = useMemo(() => {
    return historyData.filter((record) => {
      return (
        (filterIsinno
          ? record.isin_no.toLowerCase().includes(filterIsinno.toLowerCase())
          : true) &&
        (filterIssuersName
          ? record.issuer_name
              .toLowerCase()
              .includes(filterIssuersName.toLowerCase())
          : true) &&
        (filterTradeDate
          ? moment(record.trade_date).format("YYYY-MM-DD") === filterTradeDate
          : true)
      );
    });
  }, [historyData, filterIsinno, filterIssuersName, filterTradeDate]);

  const sortedData = useMemo(() => {
    return [...filteredData].sort(
      (a, b) =>
        moment(b.trade_date, "YYYY-MM-DD HH:mm:ss").valueOf() -
        moment(a.trade_date, "YYYY-MM-DD HH:mm:ss").valueOf()
    );
  }, [filteredData]);

  const paginatedData =
    rowsPerPage > 0
      ? sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      : sortedData;

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "300px",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <Box sx={{ p: 2, display: "flex", gap: 2, flexWrap: "wrap" }}>
        <TextField
          label="Filter by ISIN"
          value={filterIsinno}
          onChange={(e) => setFilterIsinno(e.target.value)}
          size="small"
        />
        <TextField
          label="Filter by Issuer's Name"
          value={filterIssuersName}
          onChange={(e) => setFilterIssuersName(e.target.value)}
          size="small"
        />
        <TextField
          label="Filter by Trade Date"
          type="date"
          value={filterTradeDate}
          onChange={(e) => setFilterTradeDate(e.target.value)}
          size="small"
          InputLabelProps={{ shrink: true }}
        />
      </Box>

      <TableContainer>
        <Table>
          <TableHead sx={{ backgroundColor: "#e9e8e8" }}>
            <TableRow>
              <TableCell>ISIN</TableCell>
              <TableCell>Issuer's Name</TableCell>
              <TableCell>Trade Date</TableCell>
              <TableCell>Turnover (Rs. in Lakhs)</TableCell>
              <TableCell>Last Trade Yield</TableCell>
              <TableCell>Last Traded Price</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedData.map((record, index) => (
              <TableRow key={index}>
                <TableCell>{record.isin_no}</TableCell>
                <TableCell>{record.issuer_name}</TableCell>
                <TableCell>
                  {moment(record.trade_date, "YYYY-MM-DD HH:mm:ss").format(
                    "YYYY-MM-DD HH:mm:ss"
                  )}
                </TableCell>
                <TableCell>{record.turnover_rs_in_lakhs}</TableCell>
                <TableCell>{record.last_trade_yield}</TableCell>
                <TableCell>{record.last_traded_price}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, { value: -1, label: "All" }]}
        component="div"
        count={filteredData.length}
        rowsPerPage={rowsPerPage > 0 ? rowsPerPage : filteredData.length}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
};

export default PriceYieldHistory;
