import React, { useEffect, useContext } from "react";
import toastr from "toastr";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  makeGETRequest,
  makeDELETERequestWithQueryParameter,
  makePOSTRequest,
  makePUTRequest,
} from "../../Api/api";
import {
  descendingComparator,
  getComparator,
  stableSort,
  headCells,
  EnhancedTableHead,
} from "./TableUtils";
import { appContext } from "../../App";
import EditIcon from "@material-ui/icons/Edit";
import DeleteModal from "../../components/DeleteModal";
import AddAdminUserModal from "./AddAdminUserModal";
import LockIcon from "@material-ui/icons/Lock";
import AdminUserPermissionsDrawer from "./AdminUserPermissionsDrawer";
import { Select, Grid, TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import PasswordIcon from "@mui/icons-material/Password";
import UpdateRatingModal from "../../components/UpdateRatingModal";
import GenericDialog from "../../components/Dialog/GenericDialog";
import IdleTimoutConfig from "../../Helpers/IdleTimeoutConfig";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "96%",
    margin: "35px auto",
    height: " 0px",
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

function removePermissionReference(permission, permissions) {
  return permissions.filter((perm) => perm !== permission);
}

function removeSecurityManagementReference(permissionMappings) {
  let updated = [];
  let mapped = permissionMappings.map((mapping) => {
    let formatted = {
      adminUser: mapping.adminUser,
    };
    formatted.permissions = removePermissionReference(
      "Security Management",
      mapping.permissions
    );
    updated.push(formatted);
  });
  return updated;
}

function deletePermissions(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function addNewPermissions(a, b) {
  return [...a, ...deletePermissions(b, a)];
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function getFilteredPermissionsForUser(adminUserUUID, permissionMappings) {
  return permissionMappings.filter(
    (permissionMapping) => permissionMapping.adminUser.uuid === adminUserUUID
  )[0].permissions;
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AdminUserDashboard = (props) => {
  const classes = useStyles();
  const { authUser, setAuthUser } = useContext(appContext);
  const [adminUsers, setAdminUsers] = React.useState([]);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("name");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [toBeDeleted, setToBeDeleted] = React.useState(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [toBeEdited, setToBeEdited] = React.useState(false);
  const [showAddAdminUserModal, setShowAddAdminUserModal] =
    React.useState(false);
  const [editMode, setEditMode] = React.useState(false);
  const [showPermissionsDialog, setShowPermissionsDialog] =
    React.useState(false);
  const [permissions, setPermissions] = React.useState([]);
  const allPermissions = [
    "Holiday Master",
    "Issuer Master",
    "Rating Organization Master",
    "Security Type",
    "Security Master",
    "Quotes",
    "Users",
    "Orders",
    "Create Orders",
    "GSEC Upload",
    "Wallet",
    "Transactions",
    "Dashboard",
    "Whatsapp Campaign",
    "KYC Module",
    "Yield Calculator",
    "IPO",
    "SGB",
    "Bid Wars",
  ];
  const [availablePermissions, setAvailablePermissions] = React.useState([
    allPermissions,
  ]);
  const [availableForChangePassword, setavailableForChangePassword] =
    React.useState("");
  const [showEnterPasswordModal, setShowEnterPasswordModal] =
    React.useState(false);
  const [showChangePasswordModal, setShowChangePasswordModal] =
    React.useState(false);
  const [enteredPassword, setEnteredPassword] = React.useState("");
  const initialState = {
    firstName: "",
    lastName: "",
    emailId: "",
    mobileNumber: "",
    password: "",
    can_see_all: false,
  };
  const [currentAdminUserForPermissions, setCurrentAdminUserForPermissions] =
    React.useState(initialState);
  const [permissionMappings, setPermissionMappings] = React.useState([]);

  useEffect(() => {
    if (authUser && authUser.isSuperuser) {
      let url = "users/list/";
      makeGETRequest(url)
        .then((response) => {
          if (response && response.status === 200) {
            setAdminUsers(response.data);
            if (response.data.length === 0)
              toastr.warning("No Admin users found !");
          }
        })
        .catch((error) => toastr.error(error));
    }
  }, []);

  useEffect(() => {
    makeGETRequest("users/permissions/list/")
      .then((response) => {
        if (response && response.status === 200) {
          setPermissionMappings(
            removeSecurityManagementReference(response.data)
          );
        }
      })
      .catch((error) => toastr.error(error));
  }, []);

  function submitPasswordForValidation(event, password) {
    event.preventDefault();
    let requestURL = `/admin/superuser/validate_password/`;
    makePUTRequest(requestURL, {
      userUUID: authUser.uuid,
      password: password,
    })
      .then((response) => {
        if (response && response.status === 200) {
          if (response.data.isValid) {
            setShowEnterPasswordModal(false);
            setShowChangePasswordModal(true);
          } else {
            toastr.error("Invalid Password");
          }
        }
      })
      .then((error) => toastr.error(error));
  }

  function closeEnterPasswordModal(event) {
    event.preventDefault();
    setavailableForChangePassword(null);
    setShowEnterPasswordModal(false);
  }

  function generateInputProps() {
    return (
      <Grid item xs={12}>
        <TextField
          id="outlined-read-only-input"
          style={{
            width: "400px",
            margin: "20px",
            justifyContent: "center",
          }}
          type="password"
          label={"Enter New Password"}
          value={enteredPassword}
          onChange={(event) => setEnteredPassword(event.target.value)}
          variant="outlined"
        />
      </Grid>
    );
  }

  const closeAddAdminUserModal = (event) => {
    event.preventDefault();
    setShowAddAdminUserModal(false);
    setEditMode(false);
    setToBeDeleted(null);
  };

  const updateAdminUserDetails = (event, state) => {
    event.preventDefault();
    state.adminUserUUID = toBeEdited.uuid;
    let url = "users/update/";
    makePUTRequest(url, state)
      .then((response) => {
        if (response && response.status === 200) {
          setShowAddAdminUserModal(false);
          setAdminUsers(response.data);
          setToBeEdited(initialState);
          setEditMode(false);
          toastr.success("Successfully updated admin user details !");
        }
      })
      .catch((error) => toastr.error(error));
  };

  const submitAdminUserDetails = (event, state) => {
    event.preventDefault();
    state.superuserUUID = authUser.uuid;
    let url = "users/create/";
    makePOSTRequest(url, state)
      .then((response) => {
        if (response && (response.status === 201 || response.status === 200)) {
          setAdminUsers(response.data);
          setShowAddAdminUserModal(false);
          toastr.success("Admin User created successfully !");
        }
      })
      .catch((error) => toastr.error(error));
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleEditIconOnClick = (event, row) => {
    event.preventDefault();
    initialState.firstName = row.firstName;
    initialState.lastName = row.lastName;
    initialState.mobileNumber = row.mobileNumber;
    initialState.emailId = row.emailId;
    initialState.can_see_all = row.can_see_all;
    setToBeEdited(row);
    setEditMode(true);
    setShowAddAdminUserModal(true);
  };

  const renderEditIcon = (row, labelId) => {
    return (
      <EditIcon
        onClick={(event) => handleEditIconOnClick(event, row)}
        color="primary"
        fontSize="medium"
        style={{
          float: "left",
          "margin-top": "10px",
          "margin-left": "10px",
          cursor: "pointer",
        }}
      />
    );
  };

  const handleDeleteIconOnClick = (event, row) => {
    event.preventDefault();
    setToBeDeleted(row);
    setShowDeleteModal(true);
  };

  const closeDeleteModal = (event) => {
    event.preventDefault();
    setShowDeleteModal(false);
  };

  const deleteAdminUser = (event) => {
    event.preventDefault();
    let url = `users/delete/${toBeDeleted.uuid}/`;
    makeDELETERequestWithQueryParameter(url)
      .then((response) => {
        if (response && (response.status === 200 || response.status === 204)) {
          setAdminUsers(response.data);
          toastr.success("Deleted Admin user successfully !");
          setShowDeleteModal(false);
          setToBeDeleted(null);
        }
      })
      .catch((error) => toastr.error(error));
  };

  const savePermissions = (event) => {
    event.preventDefault();
    let requestData = {
      adminUserUUID: toBeEdited.uuid,
      permissions: removePermissionReference(
        "Security Management",
        permissions
      ),
    };
    makePOSTRequest("users/permissions/add/", requestData)
      .then((response) => {
        if (response && response.status === 200) {
          setPermissionMappings(
            removeSecurityManagementReference(response.data)
          );
          setToBeEdited(initialState);
          setShowPermissionsDialog(false);
          setPermissions([]);
          toastr.success("Added permissions Successfully !");
        }
      })
      .catch((error) => toastr.error(error));
  };

  const closePermissionsDrawer = (event) => {
    event.preventDefault();
    setShowPermissionsDialog(false);
    setToBeEdited(initialState);
  };

  const removePermissions = (event, toBeRemoved) => {
    event.preventDefault();
    let updatedPermissions = permissions;
    updatedPermissions = deletePermissions(updatedPermissions, toBeRemoved);
    setPermissions(
      removePermissionReference("Security Management", updatedPermissions)
    );
    setAvailablePermissions(availablePermissions.concat(toBeRemoved));
  };

  const addPermissions = (event, permission) => {
    event.preventDefault();
    let updatedPermissions = permissions;
    let remainingPermissions = deletePermissions(
      availablePermissions,
      permission
    );
    setAvailablePermissions(
      removePermissionReference("Security Management", remainingPermissions)
    );
    setPermissions(addNewPermissions(permission, updatedPermissions));
  };

  const handlePermissionsIconOnClick = (event, row) => {
    event.preventDefault();
    setShowPermissionsDialog(true);
    setToBeEdited(row);
    let currentUserPermissions = getFilteredPermissionsForUser(
      row.uuid,
      permissionMappings
    );
    setAvailablePermissions(
      deletePermissions(allPermissions, currentUserPermissions)
    );
    setPermissions(currentUserPermissions);
    setCurrentAdminUserForPermissions(row);
  };

  function handleChangePasswordOnClick(event, row) {
    event.preventDefault();
    setavailableForChangePassword(row);
    setShowChangePasswordModal(true);
  }

  function renderChangePasswordIcon(row, labelId) {
    return (
      <TableCell id={labelId} scope="row" padding="none">
        <PasswordIcon
          onClick={(event) => handleChangePasswordOnClick(event, row)}
          color="primary"
          style={{
            cursor: "pointer",
          }}
        />
      </TableCell>
    );
  }

  const renderPermissionsIcon = (row, labelId) => {
    return (
      <TableCell id={labelId} scope="row" padding="none">
        <LockIcon
          onClick={(event) => handlePermissionsIconOnClick(event, row)}
          color="primary"
          style={{
            cursor: "pointer",
          }}
        />
      </TableCell>
    );
  };

  const renderDeleteIcon = (row, labelId) => {
    return (
      <TableCell id={labelId} scope="row" padding="none">
        <DeleteIcon
          onClick={(event) => handleDeleteIconOnClick(event, row)}
          color="primary"
          style={{
            cursor: "pointer",
          }}
        />
      </TableCell>
    );
  };

  const handleAddAdminUserClick = (event) => {
    event.preventDefault();
    setShowAddAdminUserModal(true);
  };

  const renderUserPermissionDialog = () => {
    if (showPermissionsDialog) {
      let currentAdminUserName =
        currentAdminUserForPermissions.firstName +
        " " +
        currentAdminUserForPermissions.lastName;
      return (
        <>
          <div>
            <Dialog
              open={showPermissionsDialog}
              TransitionComponent={Transition}
              keepMounted
              onClose={closePermissionsDrawer}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle id="alert-dialog-slide-title">
                {`Select Permissions for ${currentAdminUserName}`}
              </DialogTitle>
              <DialogContent>
                <AdminUserPermissionsDrawer
                  open={showPermissionsDialog}
                  onClose={closePermissionsDrawer}
                  addedPermissions={permissions}
                  addPermission={addPermissions}
                  removePermissions={removePermissions}
                  availablePermissions={availablePermissions}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={closePermissionsDrawer} color="primary">
                  Cancel
                </Button>
                <Button onClick={savePermissions} color="primary">
                  Save
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </>
      );
    } else return "";
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function handleSubmit(event) {
    event.preventDefault();
    let requestURL = "/users/change_password/";
    makePUTRequest(requestURL, {
      adminUserUUID: availableForChangePassword.uuid,
      password: enteredPassword,
    })
      .then((response) => {
        if (response && response.status === 200) {
          toastr.success("Password has been sent to registered email ID");
          setavailableForChangePassword(null);
          setEnteredPassword("");
          setShowChangePasswordModal(false);
        }
      })
      .catch((error) => toastr.error(error));
  }

  function closeDialog(event) {
    event.preventDefault();
    setEnteredPassword("");
    setavailableForChangePassword(null);
    setShowChangePasswordModal(false);
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <div style={{ fontSize: "40px", float: "left" }}>Admin Users</div>
        <AddCircleIcon
          onClick={handleAddAdminUserClick}
          color="primary"
          fontSize="large"
          style={{
            float: "right",
            "margin-top": "29px",
            "margin-right": "30px",
            cursor: "pointer",
          }}
        />
        <TableContainer component={Paper}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={adminUsers.length}
            />
            <TableBody>
              {stableSort(adminUsers, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.name}
                      selected={isItemSelected}
                    >
                      <TableCell align="left">{index + 1}</TableCell>
                      <TableCell id={labelId} scope="row" padding="none">
                        {row.firstName}
                      </TableCell>
                      <TableCell id={labelId} scope="row" padding="none">
                        {row.lastName}
                      </TableCell>
                      <TableCell id={labelId} scope="row" padding="none">
                        {row.emailId}
                      </TableCell>
                      <TableCell id={labelId} scope="row" padding="none">
                        {row.mobileNumber}
                      </TableCell>
                      <TableCell id={labelId} scope="row" padding="none">
                        {row.superuserName}
                      </TableCell>
                      {renderEditIcon(row, labelId)}
                      {renderDeleteIcon(row, labelId)}
                      {renderPermissionsIcon(row, labelId)}
                      {renderChangePasswordIcon(row, labelId)}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <DeleteModal
          primaryText="Delete Admin User ?"
          secondaryText="Are you sure you want to delete this Admin user ?"
          isOpen={showDeleteModal}
          handleClose={closeDeleteModal}
          confirmDelete={deleteAdminUser}
        />
        <AddAdminUserModal
          onClose={closeAddAdminUserModal}
          show={showAddAdminUserModal}
          onSubmit={submitAdminUserDetails}
          initialState={initialState}
          editMode={editMode}
          toBeEdited={toBeEdited}
          onUpdate={updateAdminUserDetails}
        />
        {renderUserPermissionDialog()}
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={adminUsers.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
        {showEnterPasswordModal ? (
          <UpdateRatingModal
            show={showEnterPasswordModal}
            heading="Enter Current Password"
            currentRating={""}
            type="password"
            onCloseUpdateModal={closeEnterPasswordModal}
            handleSubmit={submitPasswordForValidation}
          />
        ) : (
          ""
        )}
        {showChangePasswordModal ? (
          <GenericDialog
            show={showChangePasswordModal}
            heading="Enter New Password"
            inputProps={generateInputProps()}
            onClose={closeDialog}
            handleSubmit={handleSubmit}
          />
        ) : (
          ""
        )}
      </Paper>
      {/* <IdleTimoutConfig> </IdleTimoutConfig> */}
    </div>
  );
};
export default AdminUserDashboard;
