import React, { useContext } from "react";
import toastr from "toastr";
import { Grid, Typography, Tooltip } from "@material-ui/core/";
import ReactApexChart from "react-apexcharts";
import padlockImg from "../../images/padlock.png";
import { appContext } from "../../App";
import {
  makeGETRequestOnUserService,
  makeGETRequestOnActivityLogsService,
  makeGETRequest,
  makePOSTRequestOnUserService,
  makePOSTRequest,
} from "../../Api/api";
import { withStyles } from "@material-ui/core/styles";
import ActivityLogs from "../../components/ActivityLogs/ActivityLogs";
import LoggedInUsers from "../../components/LoggedInUsers/LoggedInUsers";
import IdleTimoutConfig from "../../Helpers/IdleTimeoutConfig";
import { Box, Button } from "@mui/material";
import { StyledTextInput } from "../../components/StyledComponents/StyledInputs";
import moment from "moment";

const DateTip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
    marginLeft: 5,
  },
}))(Tooltip);

function makeSeries(labels) {
  return {
    legend: {
      position: "bottom",
    },
    dataLabels: {
      enabled: false,
    },
    labels,
  };
}

function sortByTimestamp(records) {
  return records.sort(function (a, b) {
    return new Date(b.insertedAt) - new Date(a.insertedAt);
  });
}

function addSerialNumber(records) {
  let formatted = [];
  for (var iter = 0; iter < records.length; iter++) {
    records[iter].serial = iter + 1;
    formatted.push(records[iter]);
  }
  return formatted;
}

const transformStatisticsData = (data) => {
  let result = [];
  for (let key in data) {
    if (typeof data[key] === "object") {
      for (let subKey in data[key]) {
        result.push({ key: `${key}.${subKey}`, value: data[key][subKey] });
      }
    } else {
      result.push({ key, value: data[key] });
    }
  }
  return result;
};

const Tiles = ({ data, heading }) => (
  <Box sx={{ p: 2, bgcolor: "#FFFFFF" }}>
    <Typography
      variant="h6"
      style={{
        marginBottom: 10,
        fontSize: 14,
        fontWeight: 800,
        background: "#EC5056",
        color: "#FFFFFF",
        padding: 5,
        borderRadius: 8,
        transform: "skew(-13deg)",
      }}
    >
      {heading}
    </Typography>
    <Grid container spacing={3}>
      {data.map((item, index) => (
        <Grid key={index} item xs={6} sm={3} md={2}>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              p: 2,
              bgcolor: "#7659EE",
              color: "#FFFFFF",
              borderRadius: 3,
            }}
          >
            <Typography style={{ marginBottom: 5 }}>
              {item.key
                .split("_")
                .map(
                  (word) =>
                    word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                )
                .join(" ")}
            </Typography>
            <Typography style={{ fontWeight: 800, fontSize: 25 }}>
              {item.value}
            </Typography>
          </Box>
        </Grid>
      ))}
    </Grid>
  </Box>
);

export default function NewDashboard(props) {
  const { authUser, setAuthUser } = useContext(appContext);
  const currentPermissions = localStorage.getItem("permissions");
  const defaultState = {
    totalOrders: 0,
    settledOrders: 0,
    cancelledOrders: 0,
    totalSales: 0,
    totalRevenue: 0,
    totalBlockedFunds: 0,
    totalCustomers: 0,
    totalSignUps: 0,
    totalKycs: 0,
    totalSubscribers: 0,
  };
  const [currentState, setCurrentState] = React.useState(defaultState);
  const [allOrders, setAllOrders] = React.useState([]);
  const [allUsers, setAllUsers] = React.useState([]);
  const [newsletterUsers, setNewsletterUsers] = React.useState([]);
  const [activityLogs, setActivityLogs] = React.useState([]);
  const [isAdminUserCondition, setIsAdminUserCondition] = React.useState(false);
  const [loggedInUsers, setLoggedInUsers] = React.useState([]);
  const [userAndRfqStatistics, setUserAndRfqStatistics] = React.useState([]);
  const [ipoStatistics, setIpoStatistics] = React.useState([]);
  const [fetchAll, setFetchAll] = React.useState(false);
  const [date, setDate] = React.useState(moment().format("YYYY-MM"));

  React.useEffect(() => {
    makeGETRequestOnUserService("/user/active_session/list/")
      .then((response) => {
        setLoggedInUsers(addSerialNumber(response.data));
      })
      .catch((error) => toastr.error(error));
  }, []);

  React.useEffect(() => {
    setIsAdminUserCondition(
      !(authUser.isSuperuser || currentPermissions.includes("Dashboard"))
    );
  }, [currentState]);

  React.useEffect(() => {
    if (!isAdminUserCondition) {
      makeGETRequestOnActivityLogsService("/activity_logs/list/")
        .then((response) => {
          setActivityLogs(response.data.reverse());
        })
        .catch((error) => toastr.error(error));
    }
  }, []);

  React.useEffect(() => {
    if (!isAdminUserCondition) {
      makeGETRequest("/admin/news_letter_user/list/")
        .then((response) => {
          let updatedState = currentState;
          updatedState.totalSubscribers = response.data.length;
          setCurrentState(updatedState);
          setNewsletterUsers(response.data);
        })
        .catch((error) => toastr.error(error));
    }
  }, []);

  React.useEffect(() => {
    if (!isAdminUserCondition && !fetchAll) {
      makePOSTRequestOnUserService("/order_management_v2/order/summary/", {
        year: moment(date, "YYYY-MM").format("YYYY"),
        month: moment(date, "YYYY-MM").format("M"),
      })
        .then((response) => {
          setUserAndRfqStatistics(transformStatisticsData(response.data));
        })
        .catch((error) => toastr.error(error));
    }
  }, [date]);

  React.useEffect(() => {
    if (!isAdminUserCondition && !fetchAll) {
      makePOSTRequest("/ipo/all_ipo_count/", {
        year: moment(date, "YYYY-MM").format("YYYY"),
        month: moment(date, "YYYY-MM").format("M"),
      })
        .then((response) => {
          setIpoStatistics(transformStatisticsData(response.data));
        })
        .catch((error) => toastr.error(error));
    }
  }, [date]);

  React.useEffect(() => {
    if (!isAdminUserCondition && fetchAll) {
      makeGETRequestOnUserService(
        "order_management_v2/order/detail_users_summary/"
      )
        .then((response) => {
          setUserAndRfqStatistics(transformStatisticsData(response.data));
          setFetchAll(false);
        })
        .catch((error) => {
          toastr.error(error);
          setFetchAll(false);
        });
    }
  }, [fetchAll]);

  React.useEffect(() => {
    if (!isAdminUserCondition && fetchAll) {
      makeGETRequest("/ipo/total_ipo_count/")
        .then((response) => {
          setIpoStatistics(transformStatisticsData(response.data));
          setFetchAll(false);
        })
        .catch((error) => {
          toastr.error(error);
          setFetchAll(false);
        });
    }
  }, [fetchAll]);

  React.useEffect(() => {
    if (!isAdminUserCondition) {
      makeGETRequestOnUserService("/users/dashboard/info/get/")
        .then((response) => {
          if (response && response.status === 200) {
            setCurrentState(getUpdatedStats(currentState, response.data));
            setAllOrders(response.data.orders);
            setAllUsers(response.data.users);
          }
        })
        .catch((error) => toastr.error(error));
    }
  }, []);

  function getUpdatedStats(currentState, information) {
    let updatedState = currentState;
    let todaysOrders = information.orders; // Adding all orders for now
    updatedState.totalOrders = todaysOrders.length;
    updatedState.cancelledOrders = todaysOrders.filter(
      (order) => order.orderStatus === "Cancelled"
    ).length;
    updatedState.settledOrders = todaysOrders.filter(
      (order) => order.orderStatus === "Settled"
    ).length;
    updatedState.totalCustomers = information.users.length;
    updatedState.totalSignUps = information.users.length;
    updatedState.totalKycs = 0;
    return updatedState;
  }

  return (
    <Grid className="">
      {!isAdminUserCondition && (
        <Box sx={{ m: "0 auto", p: [2, 4] }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={true}>
              <Typography variant="h6">Overall Statistics</Typography>
            </Grid>
            <Grid item xs="auto">
              <Button
                variant="contained"
                onClick={() => {
                  setFetchAll(true);
                  setDate("");
                }}
              >
                Fetch All Data
              </Button>
            </Grid>
            <Grid item xs="auto">
              <StyledTextInput
                type="month"
                value={date}
                onChange={(event) => setDate(event.target.value)}
              />
            </Grid>
          </Grid>
          <hr />
          {userAndRfqStatistics.length && (
            <>
              <Tiles
                heading="Users"
                data={userAndRfqStatistics.filter(
                  (item) => !item.key.startsWith("rfq")
                )}
              />
              <Tiles
                heading="RFQ Orders"
                data={userAndRfqStatistics.filter((item) =>
                  item.key.startsWith("rfq")
                )}
              />
            </>
          )}
          {ipoStatistics.length && (
            <Tiles heading="IPO Orders" data={ipoStatistics} />
          )}
        </Box>
      )}

      <Grid container spacing={3}>
        <Grid item md={12} xs={12}>
          <Grid container spacing={3}>
            <Grid item md={12} xs={12}>
              <Grid className="portfolioAllocation">
                <Typography variant="h6" noWrap>
                  Statistics
                </Typography>
                {
                  <Grid container spacing={3}>
                    <Grid item md={4} xs={12}>
                      {
                        <>
                          <Grid className="portfolioAllocationValue">
                            {isAdminUserCondition && (
                              <Grid className="lookBg">
                                <img
                                  src={padlockImg}
                                  alt="padLock"
                                  width="30"
                                  height="30"
                                />
                              </Grid>
                            )}
                            <Typography variant="h6" noWrap>
                              {isAdminUserCondition
                                ? "Nothing to see here"
                                : "Orders"}
                            </Typography>
                            {isAdminUserCondition ? (
                              ""
                            ) : (
                              <ReactApexChart
                                options={makeSeries([
                                  "Total Orders",
                                  "Cancelled Orders",
                                  "Settled Orders",
                                ])}
                                series={[
                                  currentState.totalOrders,
                                  currentState.cancelledOrders,
                                  currentState.settledOrders,
                                ]}
                                type="pie"
                                width="100%"
                              />
                            )}
                          </Grid>
                        </>
                      }
                    </Grid>

                    <Grid item md={4} xs={12}>
                      {true && (
                        <>
                          <Grid className="portfolioAllocationValue">
                            {isAdminUserCondition && (
                              <Grid className="lookBg">
                                {" "}
                                <img
                                  src={padlockImg}
                                  alt="padLock"
                                  width="30"
                                  height="30"
                                />
                              </Grid>
                            )}
                            <Typography variant="h6" noWrap>
                              {isAdminUserCondition
                                ? "Nothing to see here"
                                : "Users"}
                            </Typography>
                            {isAdminUserCondition ? (
                              ""
                            ) : (
                              <ReactApexChart
                                options={makeSeries([
                                  "Total Customers",
                                  "Total Sign Ups",
                                  "Total KYCs",
                                  "Total Subscribers",
                                ])}
                                series={[
                                  currentState.totalCustomers,
                                  currentState.totalSignUps,
                                  currentState.totalKycs,
                                  currentState.totalSubscribers,
                                ]}
                                type="pie"
                                width="100%"
                              />
                            )}
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </Grid>
                }
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {activityLogs.length > 0 ? (
          <Grid item md={12} xs={12}>
            <div style={{ padding: 20 }}>
              <Grid container spacing={5} style={{ gap: 250 }}>
                <Grid item md={4} xs={12} style={{ minWidth: "960px" }}>
                  <Grid className="dashCard">
                    {isAdminUserCondition && (
                      <Grid className="lookBg">
                        <img
                          src={padlockImg}
                          alt="padLock"
                          width="30"
                          height="30"
                        />
                      </Grid>
                    )}
                    <DateTip
                      placement="right"
                      interactive
                      title={
                        <Typography variant="h6" style={{ color: "#2A324C" }}>
                          {isAdminUserCondition
                            ? "Nothing to see here"
                            : "Activity Logs"}
                        </Typography>
                      }
                      enterTouchDelay="10"
                    >
                      <Grid className="cardValue">
                        <Typography variant="h6" noWrap>
                          {isAdminUserCondition
                            ? "Nothing to see here"
                            : "Activity Logs"}
                        </Typography>
                        {isAdminUserCondition ? (
                          "Nothing to see here"
                        ) : (
                          <ActivityLogs
                            activityLogs={sortByTimestamp(
                              activityLogs.slice(0, 10)
                            )}
                          />
                        )}
                      </Grid>
                    </DateTip>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Grid>
        ) : (
          ""
        )}

        <Grid item md={12} xs={12}>
          <div style={{ padding: 20 }}>
            <Grid container spacing={5} style={{ gap: 250 }}>
              <Grid item md={4} xs={12} style={{ minWidth: "850px" }}>
                <Grid className="dashCard">
                  {isAdminUserCondition && (
                    <Grid className="lookBg">
                      <img
                        src={padlockImg}
                        alt="padLock"
                        width="30"
                        height="30"
                      />
                    </Grid>
                  )}
                  <DateTip
                    placement="right"
                    interactive
                    title={
                      <Typography variant="h6" style={{ color: "#2A324C" }}>
                        {isAdminUserCondition ? "" : "Logged In Users"}
                      </Typography>
                    }
                    enterTouchDelay="10"
                  >
                    <Grid className="cardValue">
                      <Typography variant="h6" noWrap>
                        {isAdminUserCondition ? "" : "Logged In Users"}
                      </Typography>
                      {isAdminUserCondition ? (
                        ""
                      ) : (
                        <LoggedInUsers loggedInUsers={loggedInUsers} />
                      )}
                    </Grid>
                  </DateTip>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
      {/* <IdleTimoutConfig></IdleTimoutConfig> */}
    </Grid>
  );
}
