import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import { makeGETRequestOnUserService } from "../../Api/api";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { StyledTextInput } from "../StyledComponents/StyledInputs";
import toastr from "toastr";

interface RiskProfileInterface {
  uuid: string;
  user: string;
  age: string;
  residential_status: string;
  investment_background: string;
  financial_goal: string;
  preference: string;
  investment_horizon: string;
  inserted_at: Date;
  updated_at: Date;
}

const RiskProfile = (props: any) => {
  const { currentUser } = props;
  const [riskProfile, setRiskProfile] = useState<RiskProfileInterface | null>(
    null
  );
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let active = true;
    if (!loading) {
      return undefined;
    }
    makeGETRequestOnUserService(`user/get_risk_profile/${currentUser.uuid}/`)
      .then((resp) => {
        if (active && resp.status == 200) {
          setRiskProfile(resp.data);
        }
      })
      .catch((error) => toastr.error(error))
      .finally(() => {
        active && setLoading(false);
      });
    return () => {
      active = false;
    };
  }, [loading]);

  return (
    <Box sx={{ maxWidth: ["90%", "80%"], mx: "auto", my: 5 }}>
      {loading ? (
        <CircularProgress color="error" />
      ) : riskProfile ? (
        <Box sx={{ bgcolor: "#FFFFFF", p: 2, borderRadius: 5 }}>
          <Grid container spacing={1}>
            {Object.keys(riskProfile)
              .filter(
                (item) => !["uuid", "inserted_at", "updated_at"].includes(item)
              )
              .map((item, index) => (
                <Grid item xs={6} key={index}>
                  <FormControl fullWidth variant="standard">
                    <InputLabel shrink htmlFor={item}>
                      {item
                        .replace(/([A-Z])/g, " $1")
                        .replace(/^./, (str) => str.toUpperCase())}
                    </InputLabel>
                    <StyledTextInput
                      disabled
                      id={`${item}_${index}`}
                      name={item}
                      value={riskProfile[item as keyof RiskProfileInterface]}
                    />
                  </FormControl>
                </Grid>
              ))}
          </Grid>
        </Box>
      ) : (
        <Typography textAlign="center">
          Error Fetching Risk Profile Details, Pls check with backend
        </Typography>
      )}
    </Box>
  );
};

export default RiskProfile;
