import React, { ChangeEvent, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { TextField, Button, Grid } from "@mui/material";
import toastr from "toastr";
import {
  makeGETRequestOnUserService,
  makePOSTRequestOnUserService,
} from "../../Api/api";

interface FormData {
  bidWarDate: string;
  settlementDate: string;
  duration: string;
  openTime: string;
  closeTime: string;
}

const BasicInfo: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({
    bidWarDate: "",
    settlementDate: "",
    duration: "",
    openTime: "",
    closeTime: "",
  });
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchOldValues = async () => {
      try {
        const response = await makeGETRequestOnUserService(
          "bid_war_metadata_record_list/"
        );

        const openDateTime: string = response.data.bid_war_open_date_time || "";
        const closeDateTime: string =
          response.data.bid_war_close_date_time || "";
        setFormData({
          bidWarDate: openDateTime ? openDateTime.split(" ")[0] : "",
          settlementDate: response.data.settlement_date || "",
          duration: response.data.duration_in_minutes
            ? response.data.duration_in_minutes.toString()
            : "",
          openTime: openDateTime ? openDateTime.split(" ")[1].slice(0, 5) : "",
          closeTime: closeDateTime
            ? closeDateTime.split(" ")[1].slice(0, 5)
            : "",
        });
      } catch (err) {
        toastr.error("Error fetching previous values.");
      }
    };

    fetchOldValues();
  }, []);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    const payload = {
      bid_war_open_date_time:
        formData.bidWarDate && formData.openTime
          ? `${formData.bidWarDate} ${formData.openTime}:00`
          : "",
      bid_war_close_date_time:
        formData.bidWarDate && formData.closeTime
          ? `${formData.bidWarDate} ${formData.closeTime}:00`
          : "",
      settlement_date: formData.settlementDate,
      duration_in_minutes: formData.duration,
    };

    try {
      await makePOSTRequestOnUserService(
        "/bid_war_metadata_record_create/",
        payload
      );
      toastr.success("Values submitted successfully.");
    } catch (err) {
      toastr.error("Error submitting values.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box p={2}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Bid War Date"
              name="bidWarDate"
              type="date"
              value={formData.bidWarDate}
              onChange={handleChange}
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Settlement Date"
              name="settlementDate"
              type="date"
              value={formData.settlementDate}
              onChange={handleChange}
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Duration in Minutes"
              name="duration"
              type="number"
              value={formData.duration}
              onChange={handleChange}
              fullWidth
              inputProps={{ min: 0 }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Open Time"
              name="openTime"
              type="time"
              value={formData.openTime}
              onChange={handleChange}
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Close Time"
              name="closeTime"
              type="time"
              value={formData.closeTime}
              onChange={handleChange}
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>

        <Box mt={2}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={loading}
          >
            {loading ? "Submitting..." : "Submit"}
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default BasicInfo;
